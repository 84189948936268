import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import HeaderAccount from "../../../../components/HeaderAccount";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { SquareLoader } from "react-spinners";

const HomePlanDetails = () => {
  const { t } = useTranslation();
  const [property, setProperty] = useState({});
  const [loading, setLoading] = useState(true);
  const [openRoom, setOpenRoom] = useState(null);
  document.title = t("homePlanDetails.0");
  const headerTitle = t("homePlanDetails.0");
  const backwardNavigate = "/account/homeplan";
  const { id } = useParams();

  useEffect(() => {
    const fetchOrderDetails = async () => {
      try {
        const token = localStorage.getItem("token");
        const storedUser = localStorage.getItem("user");

        if (!storedUser) {
          console.warn("User not found in localStorage");
          return;
        }

        const parsedUser = JSON.parse(storedUser);
        const userId = parsedUser.id;

        const response = await axios.get(
          "https://api.emotix.dev/api/v1/e-prorab/orders",
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        const filteredOrders = response.data.filter(
          (order) => order.user.id === userId
        );

        if (filteredOrders.length > 0) {
          const repairs = filteredOrders.map((order) => order.repair);
          const matchedProperty =
            repairs.find((o) => String(o.property.id) === String(id))
              ?.property || {};
          setProperty(matchedProperty);
        }
      } catch (error) {
        console.error("Error fetching order details:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchOrderDetails();
  }, [id]);

  const handleToggle = (room) => {
    setOpenRoom(openRoom === room ? null : room);
  };

  if (loading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
          backgroundColor: "#f5f5f5",
        }}
      >
        <SquareLoader
          color="#8c920b"
          cssOverride={{
            position: "absolute",
            top: "45%",
            bottom: "45%",
          }}
          size={100}
          speedMultiplier={1}
        />
      </div>
    );
  }

  const rooms = property.rooms || [];
  return (
    <div className="home-plan-details-section mx-3">
      <HeaderAccount title={headerTitle} backwardNavigate={backwardNavigate} />
      <div className="top-section">
        <div className="d-flex align-items-center property">
          <div className="property-icon me-2">
            <i className={`${property.propertyType?.iconType}`}></i>
          </div>
          <div className="property-title">
            <h5>{property.propertyType?.name?.text}</h5>
          </div>
        </div>
        <div className="row">
          <div className="col-6">
            <label htmlFor="area">{t("homePlanDetails.1")} (m²) :</label>
            <input
              type="text"
              value={property.area || ""}
              id="areaHouse"
              readOnly
            />
          </div>
          <div className="col-6">
            <label>{t("homePlanDetails.2")} (m) :</label>
            <input
              type="text"
              value={property.height || ""}
              id="heightWall"
              readOnly
            />
          </div>
        </div>
      </div>

      <div className="room-section">
        <h5>{t("homePlanDetails.3")}</h5>
        <div className="row g-2">
          {rooms.map((type) => (
            <div className="col-12" key={type.id}>
              <div className="d-flex align-items-center justify-content-between room-title">
                <h3>{type.roomType.name.text}</h3>
                <i
                  className={
                    openRoom === type.id
                      ? "fa-solid fa-chevron-down"
                      : "fa-solid fa-chevron-right"
                  }
                  onClick={() => handleToggle(type.id)}
                ></i>
              </div>
              {openRoom === type.id && (
                <div className="room">
                  <div className="row g-3">
                    <div className="col-6">
                      <form>
                        <label>
                          {t("homePlanDetails.4")} (m):
                          <input
                            type="text"
                            value={type.height || ""}
                            readOnly
                          />
                        </label>
                      </form>
                    </div>
                    <div className="col-6">
                      <form>
                        <label>
                          {t("homePlanDetails.5")} (m²):
                          <input type="text" value={type.area || ""} readOnly />
                        </label>
                      </form>
                    </div>
                    <div className="col-6">
                      <form>
                        <label>
                          {t("homePlanDetails.6")} (m):
                          <input
                            type="text"
                            value={type.width || ""}
                            readOnly
                          />
                        </label>
                      </form>
                    </div>
                    <div className="col-6">
                      <form>
                        <label>
                          {t("homePlanDetails.7")} (m):
                          <input
                            type="text"
                            value={type.length || ""}
                            readOnly
                          />
                        </label>
                      </form>
                    </div>
                  </div>
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default HomePlanDetails;
