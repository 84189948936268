import axios from "axios";
import React, { useEffect, useState } from "react";
import swal from "sweetalert";

const RepairStyleManager = () => {
  const [repairStyleAdmin, setRepairStyleAdmin] = useState([]);
  const [repairStyle, setRepairStyle] = useState({
    name: { az: "", en: "", ru: "" },
    image: {
      id: null,
      status: true,
    },
    status: true,
  });
  const [isEditing, setIsEditing] = useState(false);
  const [editId, setEditId] = useState(null);
  const authToken = localStorage.getItem("authToken");
  useEffect(() => {
    fetchRepairStyles();
  }, []);

  const fetchRepairStyles = () => {
    axios
      .get("https://api.emotix.dev/api/v1/e-prorab/repair-styles/admin", {
        headers: { Authorization: `Bearer ${authToken}` },
      })
      .then((response) => setRepairStyleAdmin(response.data))
      .catch((error) => console.log(error));
  };

  const handleInput = (event) => {
    const { name, value } = event.target;
    setRepairStyle((prevType) => ({
      ...prevType,
      name: { ...prevType.name, [name]: value },
    }));
  };

  const uploadImage = (file) => {
    const formData = new FormData();
    formData.append("file", file);

    return axios.post(
      "https://api.emotix.dev/api/v1/e-prorab/mono-files/upload",
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${authToken}`,
        },
      }
    );
  };

  const handleImageUpload = (event) => {
    const fileInput = event.target;

    if (fileInput && fileInput.files && fileInput.files[0]) {
      const file = fileInput.files[0];

      uploadImage(file)
        .then((response) => {
          const imageId = response.data.id;
          setRepairStyle((prevStore) => ({
            ...prevStore,
            image: { ...prevStore.image, id: imageId },
          }));
        })
        .catch((error) => {
          console.log("Failed to upload image:", error);
        });
    } else {
      console.log("No file selected");
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const url = isEditing
      ? `https://api.emotix.dev/api/v1/e-prorab/repair-styles/${editId}`
      : "https://api.emotix.dev/api/v1/e-prorab/repair-styles";

    const request = isEditing
      ? axios.put(url, repairStyle, {
          headers: { Authorization: `Bearer ${authToken}` },
        })
      : axios.post(url, repairStyle, {
          headers: { Authorization: `Bearer ${authToken}` },
        });

    request
      .then(() => {
        setIsEditing(false);
        setEditId(null);
        setRepairStyle({
          name: { az: "", en: "", ru: "" },
          image: { id: null, status: true },
          status: true,
        });
        fetchRepairStyles();
        swal({
          title: "",
          text: "Repair style has been added",
          icon: "success",
          timer: 1500,
        });
      })
      .catch((error) => {
        console.log(error);
        swal({
          title: "",
          text: "Repair style has not been added",
          icon: "error",
          timer: 1500,
        });
      });
  };

  const handleEdit = (type) => {
    setRepairStyle(type);
    setEditId(type.id);
    setIsEditing(true);
  };

  const handleDelete = (id) => {
    axios
      .delete(`https://api.emotix.dev/api/v1/e-prorab/repair-styles/${id}`, {
        headers: { Authorization: `Bearer ${authToken}` },
      })
      .then(() => fetchRepairStyles())
      .catch((error) => console.log(error));
  };

  return (
    <div className="repair-style-manager">
      <h3 className="text-center">Repair Style Manager</h3>
      <form onSubmit={handleSubmit}>
        <div className="repair-style-name">
          <h5 className="text-center mb-4">Repair Style Name</h5>
          <div className="row g-3">
            <div className="col-md-4">
              <label className="form-label" htmlFor="az">
                Azerbaijani
              </label>
              <input
                className="form-control"
                type="text"
                value={repairStyle.name.az}
                onChange={handleInput}
                name="az"
                required
              />
            </div>
            <div className="col-md-4">
              <label className="form-label" htmlFor="en">
                English
              </label>
              <input
                className="form-control"
                type="text"
                value={repairStyle.name.en}
                onChange={handleInput}
                name="en"
                required
              />
            </div>
            <div className="col-md-4">
              <label className="form-label" htmlFor="ru">
                Russian
              </label>
              <input
                className="form-control"
                type="text"
                value={repairStyle.name.ru}
                onChange={handleInput}
                name="ru"
                required
              />
            </div>
            <div className="col-12">
              <label className="form-label" htmlFor="image">
                Upload Image
              </label>
              <input
                className="form-control"
                type="file"
                onChange={handleImageUpload}
                name="image"
                accept="image/*"
                required={!isEditing}
              />
            </div>
          </div>
        </div>
        <button className="btn btn-primary align-self-center px-5 mt-4">
          {isEditing ? "Save Changes" : "Add Repair Style"}
        </button>
      </form>

      <h5 className="text-center mb-4">Repair Styles List</h5>
      <div className="table-responsive">
        <table className="table table-hover m-0">
          <thead>
            <tr>
              <th scope="col" className="text-center" style={{ width: "60px" }}>
                №
              </th>
              <th scope="col">Name (az)</th>
              <th scope="col">Name (en)</th>
              <th scope="col">Name (ru)</th>
              <th scope="col">Style Image</th>
              <th
                scope="col"
                className="text-center"
                style={{ width: "100px" }}
              >
                Edit
              </th>
              <th
                scope="col"
                className="text-center"
                style={{ width: "100px" }}
              >
                Delete
              </th>
            </tr>
          </thead>
          <tbody>
            {repairStyleAdmin.map((type, index) => {
              const base64String = `data:image/png;base64,${type.image.hashBase64}`;
              return (
                <tr key={type.id}>
                  <th scope="row" className="text-center">
                    {index + 1}
                  </th>
                  <td>{type.name.az}</td>
                  <td>{type.name.en}</td>
                  <td>{type.name.ru}</td>
                  <td>
                    <img
                      src={base64String}
                      width={150}
                      alt={type.name.az}
                      className="design-style-image"
                    />
                  </td>
                  <td className="text-center">
                    <button
                      className="btn btn-outline-warning btn-sm"
                      onClick={() => handleEdit(type)}
                    >
                      <i className="bi bi-pencil-square"></i>
                      Edit
                    </button>
                  </td>
                  <td className="text-center">
                    <button
                      className="btn btn-outline-danger btn-sm"
                      onClick={() => handleDelete(type.id)}
                    >
                      <i className="bi bi-trash3"></i>
                      Delete
                    </button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default RepairStyleManager;
