import React, { useState, useEffect } from "react";
import { useNavigate, Outlet } from "react-router-dom";
import AdminLogo from "../../../assets/images/AdminLogo.png";

const Dashboard = () => {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(true);
  const [isMobileOpen, setIsMobileOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  // Ekran boyutu değişikliğini dinle
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const manager = [
    {
      id: 1,
      name: "Property Type Manager",
      navigate: "propertytypemanager",
      icon: "bi bi-house-door",
    },
    {
      id: 2,
      name: "Repair Style Manager",
      navigate: "repairstylemanager",
      icon: "bi bi-tools",
    },
    {
      id: 3,
      name: "Repair Tariff Manager",
      navigate: "repairtariffmanager",
      icon: "bi bi-cash-coin",
    },
    {
      id: 4,
      name: "Technical Area Manager",
      navigate: "technicalareamanager",
      icon: "bi bi-wrench",
    },
    {
      id: 5,
      name: "Space Manager",
      navigate: "spacemanager",
      icon: "bi bi-grid-3x3",
    },
    {
      id: 6,
      name: "Room Type Manager",
      navigate: "roomtypemanager",
      icon: "bi bi-door-open",
    },
    {
      id: 7,
      name: "Material Manager",
      navigate: "materialmanager",
      icon: "bi bi-box-seam",
    },
    {
      id: 8,
      name: "Material Category Manager",
      navigate: "materialcategorymanager",
      icon: "bi bi-collection",
    },
    {
      id: 9,
      name: "Store Manager",
      navigate: "storemanager",
      icon: "bi bi-shop",
    },
    {
      id: 10,
      name: "Dependent Material Manager",
      navigate: "dependentmaterialmanager",
      icon: "bi bi-boxes",
    },
    {
      id: 11,
      name: "Repair Man Manager",
      navigate: "repairmanmanager",
      icon: "bi bi-person-gear",
    },
    {
      id: 12,
      name: "Property Status Manager",
      navigate: "propertystatusmanager",
      icon: "bi bi-clipboard-check",
    },
    {
      id: 13,
      name: "Repair Team Manager",
      navigate: "repairteammanager",
      icon: "bi bi-people",
    },
  ];

  const handleExit = () => {
    localStorage.removeItem("authToken"); // Token'ı sil
    navigate("/adminlogin"); // Ana sayfaya yönlendir
  };

  return (
    <div className="dashboard-wrapper">
      {/* Mobile Header */}
      <div className="mobile-header">
        <button
          className="mobile-menu-toggle"
          onClick={() => setIsMobileOpen(!isMobileOpen)}
        >
          <i className="bi bi-list"></i>
        </button>
        <img
          src={AdminLogo}
          alt="Admin Logo"
          className="mobile-logo"
          onClick={() => navigate("/dashboard")}
          style={{ cursor: "pointer" }}
        />
      </div>

      {/* Sidebar */}
      <div
        className={`sidebar-container ${!isOpen ? "collapsed" : ""} ${
          isMobileOpen ? "mobile-open" : ""
        }`}
      >
        <div className="sidebar-header">
          <img
            src={AdminLogo}
            alt="Admin Logo"
            className="sidebar-logo"
            onClick={() => navigate("/dashboard")}
            style={{ cursor: "pointer" }}
          />
          <button className="sidebar-toggle" onClick={() => setIsOpen(!isOpen)}>
            <i
              className={`bi ${
                isOpen ? "bi-chevron-left" : "bi-chevron-right"
              }`}
            ></i>
          </button>
        </div>
        <div className="sidebar-menu">
          {manager.map((type) => (
            <button
              key={type.id}
              className="sidebar-item"
              onClick={() => {
                navigate(`/dashboard/${type.navigate}`);
                if (isMobile) {
                  setIsMobileOpen(false);
                }
              }}
            >
              <i className={type.icon}></i>
              <span>{type.name}</span>
            </button>
          ))}

          {/* Exit butonu */}
          <button className="sidebar-item exit-button" onClick={handleExit}>
            <i className="bi bi-box-arrow-right"></i>
            <span>Exit</span>
          </button>
        </div>
      </div>

      <div className={`main-content ${!isOpen ? "content-expanded" : ""}`}>
        <Outlet />
      </div>
    </div>
  );
};

export default Dashboard;
