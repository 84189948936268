import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import HeaderAccount from "../../../components/HeaderAccount";
import { useTranslation } from "react-i18next";
import { SquareLoader } from "react-spinners";
import axios from "axios";

const HomePlan = () => {
  const { t } = useTranslation();
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  document.title = t("homePlan.0");
  const headerTitle = t("homePlan.0");
  const backwardNavigate = "/account";
  const navigate = useNavigate();

  useEffect(() => {
    const fetchOrders = async () => {
      try {
        const token = localStorage.getItem("token");
        const storedUser = localStorage.getItem("user");

        if (!storedUser) {
          console.warn("User not found in localStorage");
          return;
        }

        const parsedUser = JSON.parse(storedUser);
        const userId = parsedUser.id;

        const response = await axios.get(
          "https://api.emotix.dev/api/v1/e-prorab/orders",
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        const filteredOrders = response.data.filter(
          (order) => order.user.id === userId
        );

        if (filteredOrders.length > 0) {
          const repairs = filteredOrders.map((order) => order.repair);
          setOrders(repairs);
        }
      } catch (error) {
        console.error("Error fetching orders:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchOrders();
  }, []);

  const handlePropertyClick = (path) => {
    navigate(`/account/homeplan/${path}`);
  };

  if (loading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
          backgroundColor: "#f5f5f5",
        }}
      >
        <SquareLoader
          color="#8c920b"
          cssOverride={{
            position: "absolute",
            top: "45%",
            bottom: "45%",
          }}
          size={100}
          speedMultiplier={1}
        />
      </div>
    );
  }

  if (!orders || orders.length === 0) {
    return <div>No orders found</div>;
  }

  return (
    <div className="account-home-plan-section mx-3">
      <HeaderAccount title={headerTitle} backwardNavigate={backwardNavigate} />
      <div className="middle-section ">
        <div className="row g-1">
          {orders.map((type, index) => (
            <div className="col-6" key={index}>
              <div
                className="box d-flex align-items-center justify-content-center flex-column"
                onClick={() => handlePropertyClick(type.property.id)}
                style={{ cursor: "pointer" }}
              >
                <div className="property-icon">
                  <i className={`${type.property.propertyType.iconType}`}></i>
                </div>
                <div className="property-title text-center">
                  <h5>{type.property.propertyType.name.text}</h5>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default HomePlan;
