import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Accordion from "../../components/Accordion";
import RadioDone from "../../assets/icons/RadioDone.svg";
import { useTranslation } from "react-i18next";
import HeaderMainPage from "../../components/HeaderMainPage";
import axios from "axios";

const Home = ({ handleSubmit }) => {
  const { t } = useTranslation();
  document.title = t("home.0");
  const [propertyType, setPropertyType] = useState([]);
  const [selectedPropertyType, setSelectedPropertyType] = useState(
    JSON.parse(sessionStorage.getItem("selectedPropertyType")) || null
  );
  const [repairStyle, setRepairStyle] = useState([]);
  const [selectedRepairStyle, setSelectedRepairStyle] = useState(
    JSON.parse(sessionStorage.getItem("selectedRepairStyle")) || null
  );
  const [propertyStatuses, setPropertyStatuses] = useState([]);
  const [selectedPropertyStatuses, setSelectedPropertyStatuses] = useState(
    JSON.parse(sessionStorage.getItem("selectedPropertyStatuses")) || null
  );

  useEffect(() => {
    axios
      .get("https://api.emotix.dev/api/v1/e-prorab/property-types")
      .then((response) => {
        setPropertyType(response.data);
      })
      .catch((error) => {
        console.error("Error fetching property types:", error);
      });

    axios
      .get("https://api.emotix.dev/api/v1/e-prorab/repair-styles")
      .then((response) => {
        setRepairStyle(response.data);
      })
      .catch((error) => {
        console.error("Error fetching repair styles:", error);
      });

    axios
      .get("https://api.emotix.dev/api/v1/e-prorab/property-statuses")
      .then((response) => {
        setPropertyStatuses(response.data);
      })
      .catch((error) => {
        console.error("Error fetching property statuses:", error);
      });
  }, []);

  const [activeButton, setActiveButton] = useState(
    selectedPropertyType ? selectedPropertyType.id : null
  );
  const [activeButtonStatus, setActiveButtonStatus] = useState(
    JSON.parse(sessionStorage.getItem("selectedPropertyStatuses"))?.id || null
  );
  const [selectedRoom, setSelectedRoom] = useState(
    selectedRepairStyle ? selectedRepairStyle.id : null
  );

  const navigate = useNavigate();
  const propertyTypes = Array.isArray(propertyType) ? propertyType : [];
  const repairStyles = Array.isArray(repairStyle) ? repairStyle : [];

  const [showError, setShowError] = useState(false);

  const localHandleSubmit = (e) => {
    const currentRepairTariff = sessionStorage.getItem("selectedRepairTariff");

    const errorConditions = {
      propertyType: !activeButton,
      propertyStatus: !activeButtonStatus,
      repairStyle: !selectedRoom,
      repairTariff: !currentRepairTariff,
    };

    if (Object.values(errorConditions).some((condition) => condition)) {
      e.preventDefault();
      setShowError(true);
    } else {
      setShowError(false);
      if (handleSubmit) handleSubmit();
      navigate("/calculator");
    }
  };

  const handleRadioClick = (type) => {
    if (selectedRoom === type.id) {
      setSelectedRoom(null);
      setSelectedRepairStyle(null);
      sessionStorage.removeItem("selectedRepairStyle");
    } else {
      setSelectedRoom(type.id);
      setSelectedRepairStyle(type);
      sessionStorage.setItem("selectedRepairStyle", JSON.stringify(type));
    }
  };

  const handleButtonClick = (type) => {
    if (activeButton === type.id) {
      setActiveButton(null);
      setSelectedPropertyType(null);
      sessionStorage.removeItem("selectedPropertyType");
    } else {
      setActiveButton(type.id);
      setSelectedPropertyType(type);
      sessionStorage.setItem("selectedPropertyType", JSON.stringify(type));
    }
  };

  const handleButtonPropertyStatus = (type) => {
    if (activeButtonStatus === type.id) {
      setActiveButtonStatus(null);
      setSelectedPropertyStatuses(null);
      sessionStorage.removeItem("selectedPropertyStatuses");
    } else {
      setActiveButtonStatus(type.id);
      setSelectedPropertyStatuses(type);
      sessionStorage.setItem("selectedPropertyStatuses", JSON.stringify(type));
    }
  };

  return (
    <div className="home-section mx-3">
      <HeaderMainPage />
      <div className="property-types d-flex gap-2">
        {propertyTypes.map((type) => (
          <button
            key={type.id}
            className="btn"
            onClick={() => handleButtonClick(type)}
            style={{
              backgroundColor: activeButton === type.id ? "#8c920b" : "#ffffff",
              color: activeButton === type.id ? "#ffffff" : "#000000",
              border:
                showError && !activeButton
                  ? "1px solid red"
                  : "1px solid transparent",
            }}
          >
            <div
              style={{
                fontSize: "2rem",
                color: activeButton === type.id ? "#ffffff" : "#8c920b",
              }}
              className="icon"
            >
              <i style={{ fontSize: 18 }} className={`${type.iconType}`}></i>
            </div>
            <h6 className="mb-4" style={{ fontWeight: 600, fontSize: 10 }}>
              {type.name.text}
            </h6>
          </button>
        ))}
      </div>
      {selectedPropertyType && (
        <>
          <h5>{t("home.4")}</h5>
          <div className="property-status-section d-flex align-items-center gap-2 my-3">
            {propertyStatuses.map((type, index) => (
              <button
                className="box d-flex align-items-center justify-content-center gap-2"
                key={index}
                onClick={() => handleButtonPropertyStatus(type)}
                style={{
                  border:
                    showError && !activeButtonStatus
                      ? "1px solid red"
                      : activeButtonStatus === type.id
                      ? "1px solid #8c920b"
                      : "1px solid #e3e3e3",
                }}
              >
                <i
                  className={`${type.iconType}`}
                  style={{
                    color:
                      activeButtonStatus === type.id ? "#8c920b" : "#e3e3e3",
                  }}
                ></i>
                <h4>{type.name.text}</h4>
              </button>
            ))}
          </div>
        </>
      )}
      <div className="design-section">
        <h4 className="design-title">{t("home.1")}</h4>
        <div className="design-rooms d-flex gap-2">
          {repairStyles.map((type) => {
            const base64String = `data:image/png;base64,${type.image.hashBase64}`;
            return (
              <button
                key={type.id}
                className="btn p-0 room bg-white"
                onClick={() => handleRadioClick(type)}
                style={{
                  border: showError && !selectedRoom ? "1px solid red" : "none",
                }}
              >
                <img
                  src={base64String}
                  alt={type.name.text}
                  className="design-style-image"
                />
                <div className="d-flex align-items-center justify-content-around">
                  <h4>{type.name?.text || "Style"}</h4>
                  <div>
                    {selectedRoom === type.id ? (
                      <img
                        src={RadioDone}
                        alt="Radio Done"
                        style={{
                          marginBottom: "8px",
                          marginRight: "8px",
                          width: "20px",
                          height: "20px",
                        }}
                      />
                    ) : (
                      <input
                        type="radio"
                        checked={false}
                        readOnly
                        style={{
                          marginBottom: "3px",
                          marginRight: "10px",
                          width: "14px",
                          height: "14px",
                          accentColor: "currentColor",
                          transform: "scale(1.2)",
                        }}
                      />
                    )}
                  </div>
                </div>
              </button>
            );
          })}
        </div>
      </div>

      <div className="definition-section">
        <h4 className="definition-title">{t("home.2")}</h4>
        <Accordion handleSubmit={localHandleSubmit} showError={showError} />
      </div>
      <div className="button-section mb-2">
        <Link to="/calculator" onClick={localHandleSubmit}>
          <button className="btn py-3 global-button">{t("home.3")}</button>
        </Link>
      </div>
    </div>
  );
};

export default Home;
