import axios from "axios";
import React, { useEffect, useState } from "react";
import swal from "sweetalert";

const MaterialManager = () => {
  const [materials, setMaterials] = useState([]);
  const [dependentMaterials, setDependentMaterials] = useState([]);
  const [categoryId, setCategoryId] = useState(null);
  const [repairStyleId, setRepairStyleId] = useState(null);
  const [categories, setCategories] = useState([]);
  const [repairStyles, setRepairStyles] = useState([]);
  const [selectedCategoryId, setSelectedCategoryId] = useState(null);
  const [selectedRepairStyleId, setSelectedRepairStyleId] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [editId, setEditId] = useState(null);
  const [material, setMaterial] = useState({
    name: { az: "", en: "", ru: "" },
    description: { az: "", en: "", ru: "" },
    categories: [{ id: null }],
    repairStyles: [{ id: null }],
    unitOfMeasure: "",
    measureCapacity: "",
    usagePerSquareMeter: "",
    lowPrice: "",
    midPrice: "",
    highPrice: "",
    servicePrice: "",
    currency: "AZN",
    image: { id: null },
    imageOnRoom: { id: null },
    isSelectable: true,
    dependentMaterials: [{ id: null }],
    status: true,
  });

  const authToken = localStorage.getItem("authToken");

  useEffect(() => {
    fetchCategories();
    fetchRepairStyles();
  }, []);

  const fetchMaterials = (categoryId, repairStyleId) => {
    if (categoryId && repairStyleId) {
      axios
        .get(
          `https://api.emotix.dev/api/v1/e-prorab/materials/filter?categoryId=${categoryId}&repairStyleId=${repairStyleId}`,
          {
            headers: { Authorization: `Bearer ${authToken}` },
          }
        )
        .then((response) => {
          setMaterials(response.data);
        })
        .catch((error) => console.error("Failed to fetch materials:", error));
    }
  };
  const fetchDependentMaterials = (categoryId, repairStyleId) => {
    if (categoryId && repairStyleId) {
      axios
        .get(
          `https://api.emotix.dev/api/v1/e-prorab/dependent-materials/filter?categoryId=${categoryId}&repairStyleId=${repairStyleId}`,
          {
            headers: { Authorization: `Bearer ${authToken}` },
          }
        )
        .then((response) => {
          setDependentMaterials(response.data);
        })
        .catch((error) => console.error("Failed to fetch materials:", error));
    }
  };
  const fetchCategories = () => {
    axios
      .get("https://api.emotix.dev/api/v1/e-prorab/material-categories", {
        headers: { Authorization: `Bearer ${authToken}` },
      })
      .then((response) => {
        setCategories(response.data);
      })
      .catch((error) => console.error("Failed to fetch categories:", error));
  };

  const fetchRepairStyles = () => {
    axios
      .get("https://api.emotix.dev/api/v1/e-prorab/repair-styles", {
        headers: { Authorization: `Bearer ${authToken}` },
      })
      .then((response) => {
        setRepairStyles(response.data);
      })
      .catch((error) => console.error("Failed to fetch repair styles:", error));
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    const numericFields = [
      "measureCapacity",
      "usagePerSquareMeter",
      "lowPrice",
      "midPrice",
      "highPrice",
      "servicePrice",
    ];

    setMaterial((prev) => ({
      ...prev,
      [name]: numericFields.includes(name) ? parseFloat(value) || 0 : value,
    }));
  };

  const handleNameDescriptionChange = (event) => {
    const { name, value, dataset } = event.target;
    setMaterial((prev) => ({
      ...prev,
      [dataset.field]: { ...prev[dataset.field], [name]: value },
    }));
  };

  const handleDropdownChange = (event, field) => {
    const { value } = event.target;
    setMaterial((prev) => ({
      ...prev,
      [field]: [{ id: parseInt(value) }],
    }));
  };
  const uploadImage = (file) => {
    const formData = new FormData();
    formData.append("file", file);

    return axios.post(
      "https://api.emotix.dev/api/v1/e-prorab/mono-files/upload",
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${authToken}`,
        },
      }
    );
  };

  const handleImageUpload = (event, type) => {
    // Ensure the event target is the input element
    const fileInput = event.target;

    // Check if files are available
    if (fileInput && fileInput.files && fileInput.files[0]) {
      const file = fileInput.files[0];

      // Proceed with the image upload
      uploadImage(file)
        .then((response) => {
          const imageId = response.data.id;
          // Update the store with the image ID
          setMaterial((prevMaterial) => {
            if (type === "image") {
              return {
                ...prevMaterial,
                image: { ...prevMaterial.image, id: imageId },
              };
            } else if (type === "imageOnRoom") {
              return {
                ...prevMaterial,
                imageOnRoom: {
                  ...prevMaterial.imageOnRoom,
                  id: imageId,
                },
              };
            }
            return prevMaterial;
          });
        })
        .catch((error) => {
          console.log("Failed to upload image:", error);
        });
    } else {
      console.log("No file selected");
    }
  };

  const handleDependentMaterialSelect = (materialId) => {
    if (
      !material.dependentMaterials.some(
        (dependentMaterial) => dependentMaterial.id === materialId
      )
    ) {
      setMaterial((prevDependentMaterial) => ({
        ...prevDependentMaterial,
        dependentMaterials: [{ id: materialId }],
      }));
    }
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    const url = isEditing
      ? `https://api.emotix.dev/api/v1/e-prorab/materials/${editId}`
      : "https://api.emotix.dev/api/v1/e-prorab/materials";

    const axiosMethod = isEditing ? axios.put : axios.post;

    axiosMethod(url, material, {
      headers: { Authorization: `Bearer ${authToken}` },
    })
      .then(() => {
        setIsEditing(false);
        setEditId(null);
        setMaterial({
          name: { az: "", en: "", ru: "" },
          description: { az: "", en: "", ru: "" },
          categories: [{ id: null }],
          repairStyles: [{ id: null }],
          unitOfMeasure: "",
          measureCapacity: 0,
          usagePerSquareMeter: 0,
          lowPrice: 0,
          midPrice: 0,
          highPrice: 0,
          servicePrice: 0,
          currency: "AZN",
          image: { id: null },
          imageOnRoom: { id: null },
          isSelectable: true,
          dependentMaterials: [{ id: null }],
          status: true,
        });
        fetchMaterials(selectedCategoryId, selectedRepairStyleId);

        swal({
          title: "",
          text: isEditing
            ? "Product has been updated"
            : "Product has been added",
          icon: "success",
          timer: 1500,
        });
      })
      .catch((error) => {
        console.error(error);
        swal({
          title: "",
          text: isEditing
            ? "Product has not been updated"
            : "Product has not been added",
          icon: "error",
          timer: 1500,
        });
      });
  };

  const handleEdit = (material) => {
    setMaterial(material);
    setEditId(material.id);
    setIsEditing(true);
  };

  const handleCategoryChange = (event) => {
    const categoryId = parseInt(event.target.value);
    setSelectedCategoryId(categoryId);
    if (selectedRepairStyleId) {
      fetchMaterials(categoryId, selectedRepairStyleId);
    }
  };

  const handleRepairStyleChange = (event) => {
    const repairStyleId = parseInt(event.target.value);
    setSelectedRepairStyleId(repairStyleId);
    if (selectedCategoryId) {
      fetchMaterials(selectedCategoryId, repairStyleId);
    }
  };
  const handleDelete = (id) => {
    axios
      .delete(`https://api.emotix.dev/api/v1/e-prorab/materials/${id}`, {
        headers: { Authorization: `Bearer ${authToken}` },
      })
      .then(() => {
        setMaterials(materials.filter((material) => material.id !== id));
      })
      .catch((error) => console.log(error));
  };
  const showData = () => {
    console.log("Material Data:", material);
    alert("Check the console for the material data.");
  };
  return (
    <div className="material-manager">
      <h3 className="text-center text-primary">Material Manager</h3>
      <form onSubmit={handleSubmit} className="d-flex flex-column gap-3">
        <div className="material-details">
          <h5 className="text-center">Material Details</h5>

          {/* Name and Description Fields */}
          {["az", "en", "ru"].map((lang) => (
            <div key={lang} className="row g-3 mb-4">
              <div className="col-md-6">
                <label className="form-label">Name ({lang})</label>
                <input
                  className="form-control"
                  type="text"
                  value={material.name[lang]}
                  onChange={handleNameDescriptionChange}
                  name={lang}
                  data-field="name"
                  required
                />
              </div>
              <div className="col-md-6">
                <label className="form-label">Description ({lang})</label>
                <input
                  className="form-control"
                  type="text"
                  value={material.description[lang]}
                  onChange={handleNameDescriptionChange}
                  name={lang}
                  data-field="description"
                  required
                />
              </div>
            </div>
          ))}

          {/* Category and Repair Style */}
          <div className="row g-3 mb-4">
            <div className="col-md-6">
              <label className="form-label">Category</label>
              <select
                className="form-select"
                value={material.categories[0]?.id || ""}
                onChange={(e) => handleDropdownChange(e, "categories")}
                required
              >
                <option value="" disabled>
                  Select Category
                </option>
                {categories.map((cat) => (
                  <option key={cat.id} value={cat.id}>
                    {cat.name.text}
                  </option>
                ))}
              </select>
            </div>
            <div className="col-md-6">
              <label className="form-label">Repair Style</label>
              <select
                className="form-select"
                value={material.repairStyles[0]?.id || ""}
                onChange={(e) => handleDropdownChange(e, "repairStyles")}
                required
              >
                <option value="" disabled>
                  Select Repair Style
                </option>
                {repairStyles.map((style) => (
                  <option key={style.id} value={style.id}>
                    {style.name.text}
                  </option>
                ))}
              </select>
            </div>
          </div>

          {/* Measurement Details */}
          <div className="row g-3 mb-4">
            <div className="col-md-6">
              <label className="form-label">Unit of Measure</label>
              <input
                className="form-control"
                type="text"
                value={material.unitOfMeasure}
                onChange={handleInputChange}
                name="unitOfMeasure"
                required
              />
            </div>
            <div className="col-md-6">
              <label className="form-label">Measure Capacity</label>
              <input
                className="form-control"
                type="number"
                value={material.measureCapacity}
                onChange={handleInputChange}
                name="measureCapacity"
                required
              />
            </div>
            <div className="col-md-6">
              <label className="form-label">Usage Per Square Meter</label>
              <input
                className="form-control"
                type="number"
                value={material.usagePerSquareMeter}
                onChange={handleInputChange}
                name="usagePerSquareMeter"
                required
              />
            </div>
          </div>

          {/* Price Details */}
          <div className="row g-3 mb-4">
            <div className="col-md-3">
              <label className="form-label">Low Price</label>
              <input
                className="form-control"
                type="number"
                value={material.lowPrice}
                onChange={handleInputChange}
                name="lowPrice"
                required
              />
            </div>
            <div className="col-md-3">
              <label className="form-label">Mid Price</label>
              <input
                className="form-control"
                type="number"
                value={material.midPrice}
                onChange={handleInputChange}
                name="midPrice"
                required
              />
            </div>
            <div className="col-md-3">
              <label className="form-label">High Price</label>
              <input
                className="form-control"
                type="number"
                value={material.highPrice}
                onChange={handleInputChange}
                name="highPrice"
                required
              />
            </div>
            <div className="col-md-3">
              <label className="form-label">Service Price</label>
              <input
                className="form-control"
                type="number"
                value={material.servicePrice}
                onChange={handleInputChange}
                name="servicePrice"
                required
              />
            </div>
            <div className="col-md-3">
              <label className="form-label">Currency</label>
              <input
                className="form-control"
                type="text"
                value={material.currency}
                onChange={handleInputChange}
                name="currency"
                required
              />
            </div>
          </div>

          {/* Dependent Materials Section */}
          <div className="dependent-materials">
            <h4 className="text-center mb-4">Dependent Material Selection</h4>
            <div className="material-select">
              <select
                className="form-select"
                onChange={(e) => {
                  setCategoryId(e.target.value);
                  fetchDependentMaterials(e.target.value, repairStyleId);
                }}
              >
                <option value="">Select Category</option>
                {categories.map((category) => (
                  <option key={category.id} value={category.id}>
                    {category.name.text}
                  </option>
                ))}
              </select>

              <select
                className="form-select"
                onChange={(e) => {
                  setRepairStyleId(e.target.value);
                  fetchDependentMaterials(categoryId, e.target.value);
                }}
              >
                <option value="">Select Repair Style</option>
                {repairStyles.map((repairStyle) => (
                  <option key={repairStyle.id} value={repairStyle.id}>
                    {repairStyle.name.text}
                  </option>
                ))}
              </select>
            </div>

            <h5 className="text-center mb-3">Available Materials</h5>
            <ol className="list-group">
              {dependentMaterials.map((dependentMaterial) => (
                <li key={dependentMaterial.id} className="list-group-item">
                  <button
                    className={`btn btn-outline-primary w-100 ${
                      material.dependentMaterials[0]?.id ===
                      dependentMaterial.id
                        ? "active"
                        : ""
                    }`}
                    type="button"
                    onClick={() =>
                      handleDependentMaterialSelect(dependentMaterial.id)
                    }
                  >
                    {dependentMaterial.name.az}
                  </button>
                </li>
              ))}
            </ol>
          </div>

          {/* Image Upload Section */}
          <div className="row g-3 mb-4">
            <div className="col-md-6">
              <label className="form-label">Upload Image</label>
              <input
                className="form-control"
                type="file"
                onChange={(e) => handleImageUpload(e, "image")}
                accept="image/*"
              />
            </div>
            <div className="col-md-6">
              <label className="form-label">Upload Image on Room</label>
              <input
                className="form-control"
                type="file"
                onChange={(e) => handleImageUpload(e, "imageOnRoom")}
                accept="image/*"
              />
            </div>
          </div>

          {/* Selectable Checkbox */}
          <div className="form-check mb-4">
            <input
              className="form-check-input"
              type="checkbox"
              checked={material.isSelectable}
              onChange={(e) =>
                setMaterial((prev) => ({
                  ...prev,
                  isSelectable: e.target.checked,
                }))
              }
              id="selectableCheck"
            />
            <label className="form-check-label" htmlFor="selectableCheck">
              Selectable
            </label>
          </div>
        </div>

        <button type="submit" className="btn btn-primary align-self-center">
          {isEditing ? "Save Changes" : "Add Material"}
        </button>
      </form>

      {/* Materials List Section */}
      <div className="materials-list">
        <h3 className="text-center mb-4">Materials List</h3>
        <div className="row g-3 mb-4">
          <div className="col-md-6">
            <select
              className="form-select"
              value={selectedCategoryId || ""}
              onChange={handleCategoryChange}
              required
            >
              <option value="" disabled>
                Select Category
              </option>
              {categories.map((cat) => (
                <option key={cat.id} value={cat.id}>
                  {cat.name.text}
                </option>
              ))}
            </select>
          </div>
          <div className="col-md-6">
            <select
              className="form-select"
              value={selectedRepairStyleId || ""}
              onChange={handleRepairStyleChange}
              required
            >
              <option value="" disabled>
                Select Repair Style
              </option>
              {repairStyles.map((style) => (
                <option key={style.id} value={style.id}>
                  {style.name.text}
                </option>
              ))}
            </select>
          </div>
        </div>

        <div className="table-responsive">
          <table className="table table-hover">
            <thead>
              <tr>
                <th scope="col" className="text-center">
                  №
                </th>
                <th scope="col">Name (az)</th>
                <th scope="col">Name (en)</th>
                <th scope="col">Name (ru)</th>
                <th scope="col">Description (az)</th>
                <th scope="col">Description (en)</th>
                <th scope="col">Description (ru)</th>
                <th scope="col">Material Category</th>
                <th scope="col">Repair Style</th>
                <th scope="col">Unit of Measure</th>
                <th scope="col">Measure Capacity</th>
                <th scope="col">Usage Per Square Meter</th>
                <th scope="col">Low Price</th>
                <th scope="col">Mid Price</th>
                <th scope="col">High Price</th>
                <th scope="col">Service Price</th>
                <th scope="col">Currency</th>
                <th scope="col">Image</th>
                <th scope="col">Image on Room</th>
                <th scope="col" className="text-center">
                  Actions
                </th>
              </tr>
            </thead>
            <tbody>
              {materials.map((type, index) => {
                const base64String = `data:image/png;base64,${type.image.hashBase64}`;
                const base64String2 = `data:image/png;base64,${type.imageOnRoom.hashBase64}`;
                return (
                  <tr key={type.id}>
                    <th scope="row" className="text-center">
                      {index + 1}
                    </th>
                    <td>{type.name.az}</td>
                    <td>{type.name.en}</td>
                    <td>{type.name.ru}</td>
                    <td>{type.description.az}</td>
                    <td>{type.description.en}</td>
                    <td>{type.description.ru}</td>
                    <td>{type.categories[0].name.az}</td>
                    <td>{type.repairStyles[0].name.az}</td>
                    <td>{type.unitOfMeasure}</td>
                    <td>{type.measureCapacity}</td>
                    <td>{type.usagePerSquareMeter}</td>
                    <td>{type.lowPrice}</td>
                    <td>{type.midPrice}</td>
                    <td>{type.highPrice}</td>
                    <td>{type.servicePrice}</td>
                    <td>{type.currency}</td>
                    <td>
                      <img
                        src={base64String}
                        alt={type.name.az}
                        width={100}
                        height={100}
                        style={{ borderRadius: "12px" }}
                      />
                    </td>
                    <td>
                      <img
                        src={base64String2}
                        alt={type.name.az}
                        width={100}
                        height={100}
                        style={{ borderRadius: "12px" }}
                      />
                    </td>
                    <td className="text-center">
                      <div className="d-flex gap-2 justify-content-center">
                        <button
                          className="btn btn-outline-warning"
                          onClick={() => handleEdit(type)}
                        >
                          <i className="bi bi-pencil-square"></i>
                          Edit
                        </button>
                        <button
                          className="btn btn-outline-danger"
                          onClick={() => handleDelete(type.id)}
                        >
                          <i className="bi bi-trash3"></i>
                          Delete
                        </button>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default MaterialManager;
