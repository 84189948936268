import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Header from "../../components/Header";
import axios from "axios";
import { SquareLoader } from "react-spinners";
import RoomDropdown from "../../components/RoomDropdown/RoomDropdown";
import { useTranslation } from "react-i18next";

const AllMaterials = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  document.title = t("allMaterials.0");
  const headerTitle = t("allMaterials.1");
  const [shareId, setShareId] = useState("");
  const [repair, setRepair] = useState([]);
  const [filteredRooms, setFilteredRooms] = useState([]);
  const [selectedRoom, setSelectedRoom] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const { id } = useParams();
  const backwardNavigate = `/allmaterials/${id}`;

  const token = localStorage.getItem("token");

  useEffect(() => {
    setIsLoading(true);
    axios
      .get(`https://api.emotix.dev/api/v1/e-prorab/repairs/share/${id}`)
      .then((response) => {
        const result = response.data.split("/").slice(6).join("/");
        setShareId(result);

        setIsLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setIsLoading(false);
      });
  }, [id]);

  useEffect(() => {
    if (shareId) {
      setIsLoading(true);
      if (!token) {
        navigate("/signup");
        return;
      }
      axios
        .get(
          `https://api.emotix.dev/api/v1/e-prorab/repairs/shared-repair/${shareId}`
        )
        .then((response) => {
          const repairData = response.data.property?.rooms || [];
          const validRooms = repairData.filter((room) =>
            room.roomComponents.some(
              (component) =>
                component.material.dependentMaterials &&
                component.material.dependentMaterials.length > 0
            )
          );
          setRepair(validRooms);
          setFilteredRooms(validRooms);
          setIsLoading(false);
        })
        .catch((error) => {
          console.error(error);
          setIsLoading(false);
        });
    }
  }, [shareId, token]);

  useEffect(() => {
    if (repair.length > 0) {
      const firstRoom = repair[0].roomType;
      setSelectedRoom(firstRoom);
      handleRoomChange(firstRoom);
    }
  }, [repair]);

  const handleRoomChange = (selectedRoom) => {
    setSelectedRoom(selectedRoom);

    if (selectedRoom) {
      const filtered = repair.filter(
        (room) => room.roomType.name.text === selectedRoom.name.text
      );
      setFilteredRooms(filtered);
    } else {
      setFilteredRooms(repair);
    }
  };

  return (
    <div className="allmaterials-section mx-3">
      {isLoading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
            backgroundColor: "#f5f5f5",
          }}
        >
          <SquareLoader
            color="#8c920b"
            cssOverride={{
              position: "absolute",
              top: "45%",
              bottom: "45%",
            }}
            size={100}
            speedMultiplier={1}
          />
        </div>
      ) : (
        <>
          <Header
            title={headerTitle}
            showProfile={true}
            backwardNavigate={backwardNavigate}
          />
          <div className="top-section d-flex align-items-center justify-content-center">
            <h5 className="my-auto">{t("allMaterials.0")}</h5>
          </div>
          <div className="middle-section">
            <RoomDropdown
              onChange={handleRoomChange}
              selectedRoomTypes={repair.map((room) => room.roomType)} // Sadece oda tiplerini gönder
            />
          </div>
          <div className="bottom-section">
            <table>
              <tbody>
                {filteredRooms.map((room, index) =>
                  room.roomComponents.map((component, compIndex) => (
                    <tr key={`${index}-${compIndex}`}>
                      <td>
                        {component.material.dependentMaterials.map(
                          (type, typeIndex) => (
                            <span key={typeIndex}>{type.name.text}</span>
                          )
                        )}
                      </td>
                      <td>
                        {component.consumption}{" "}
                        {component.material.dependentMaterials.map(
                          (type, typeIndex) => (
                            <span key={typeIndex}>{type.unitOfMeasure}</span>
                          )
                        )}
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          </div>
          <div className="button-section">
            <Link to={`/allmaterialsprice/${id}`}>
              <button className="btn py-3 global-button">
                {t("allMaterials.2")}
              </button>
            </Link>
          </div>
        </>
      )}
    </div>
  );
};

export default AllMaterials;
