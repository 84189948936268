import React, { useState, useEffect } from "react";
import axios from "axios";
import "./_RepairManManager.scss";
import swal from "sweetalert";

const RepairManManager = () => {
  const [repairMans, setRepairMans] = useState({
    name: "",
    surname: "",
    phoneNumber: "",
    technicalArea: { id: null },
    image: {
      id: null,
      status: true,
    },
    status: true,
  });
  const [repairMan, setRepairMan] = useState([]);
  const [technicalAreas, setTechnicalAreas] = useState([]);
  const [isEditing, setIsEditing] = useState(false);
  const [editId, setEditId] = useState(null);

  const authToken = localStorage.getItem("authToken");

  useEffect(() => {
    fetchTechnicalAreas();
    fetchRepairMan();
  }, []);

  const fetchTechnicalAreas = () => {
    axios
      .get("https://api.emotix.dev/api/v1/e-prorab/technical-areas/admin", {
        headers: { Authorization: `Bearer ${authToken}` },
      })
      .then((response) => {
        setTechnicalAreas(response.data);
      })
      .catch((error) =>
        console.error("Failed to fetch technical areas:", error)
      );
  };

  const fetchRepairMan = () => {
    axios
      .get("https://api.emotix.dev/api/v1/e-prorab/repairman/admin", {
        headers: { Authorization: `Bearer ${authToken}` },
      })
      .then((response) => {
        setRepairMan(response.data);
      })
      .catch((error) => console.error("Failed to fetch repairMan:", error));
  };

  const uploadImage = (file) => {
    const formData = new FormData();
    formData.append("file", file);

    return axios.post(
      "https://api.emotix.dev/api/v1/e-prorab/mono-files/upload",
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${authToken}`,
        },
      }
    );
  };

  const handleImageUpload = (event) => {
    const fileInput = event.target;
    if (fileInput && fileInput.files && fileInput.files[0]) {
      const file = fileInput.files[0];
      uploadImage(file)
        .then((response) => {
          const imageId = response.data.id;
          setRepairMans((prevRepairMan) => ({
            ...prevRepairMan,
            image: { ...prevRepairMan.image, id: imageId },
          }));
        })
        .catch((error) => console.log("Failed to upload image:", error));
    } else {
      console.log("No file selected");
    }
  };

  const handleTechnicalAreaChange = (event) => {
    const selectedId = event.target.value;
    setRepairMans((prevRepairMan) => ({
      ...prevRepairMan,
      technicalArea: { id: Number(selectedId) },
    }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const repairMansData = { ...repairMans };

    const url = isEditing
      ? `https://api.emotix.dev/api/v1/e-prorab/repairman/${editId}`
      : "https://api.emotix.dev/api/v1/e-prorab/repairman";

    const request = isEditing
      ? axios.put(url, repairMansData, {
          headers: { Authorization: `Bearer ${authToken}` },
        })
      : axios.post(url, repairMansData, {
          headers: { Authorization: `Bearer ${authToken}` },
        });

    request
      .then(() => {
        setIsEditing(false);
        setEditId(null);
        setRepairMans({
          name: "",
          surname: "",
          phoneNumber: "",
          image: { id: null, status: true },
          technicalArea: { id: null },
          status: true,
        });
        fetchRepairMan();
        swal({
          title: "",
          text: "Repairman has been added",
          icon: "success",
          timer: 1500,
        });
      })
      .catch((error) => {
        console.error("Failed to submit repairMans:", error);
        swal({
          title: "",
          text: "Repairman has not been added",
          icon: "error",
          timer: 1500,
        });
      });
  };
  const handleEdit = (type) => {
    setRepairMans(type);
    setEditId(type.id);
    setIsEditing(true);
  };

  const handleDelete = (id) => {
    axios
      .delete(`https://api.emotix.dev/api/v1/e-prorab/repairman/${id}`, {
        headers: { Authorization: `Bearer ${authToken}` },
      })
      .then(() => fetchRepairMan())
      .catch((error) => console.log(error));
  };
  return (
    <div className="repairMans-manager">
      <h3 className="text-center">RepairMan Manager</h3>
      <form onSubmit={handleSubmit} className="d-flex flex-column gap-3">
        <div className="row g-3">
          <div className="col-md-4">
            <label className="form-label">Name</label>
            <input
              className="form-control"
              type="text"
              value={repairMans.name}
              onChange={(e) =>
                setRepairMans({ ...repairMans, name: e.target.value })
              }
              required
            />
          </div>
          <div className="col-md-4">
            <label className="form-label">Surname</label>
            <input
              className="form-control"
              type="text"
              value={repairMans.surname}
              onChange={(e) =>
                setRepairMans({ ...repairMans, surname: e.target.value })
              }
              required
            />
          </div>
          <div className="col-md-4">
            <label className="form-label">Phone Number</label>
            <input
              className="form-control"
              type="text"
              value={repairMans.phoneNumber}
              onChange={(e) =>
                setRepairMans({ ...repairMans, phoneNumber: e.target.value })
              }
              required
            />
          </div>
          <div className="col-md-6">
            <label className="form-label">Upload Image</label>
            <input
              className="form-control"
              type="file"
              onChange={handleImageUpload}
              required={!isEditing}
            />
          </div>
          <div className="col-md-6">
            <label className="form-label">Technical Area</label>
            <select
              className="form-control"
              value={repairMans.technicalArea.id || ""}
              onChange={handleTechnicalAreaChange}
              required
            >
              <option value="" disabled>
                Select Technical Area
              </option>
              {technicalAreas.map((area) => (
                <option key={area.id} value={area.id}>
                  {area.name.az}
                </option>
              ))}
            </select>
          </div>
        </div>
        <button className="btn btn-primary align-self-center px-5">
          {isEditing ? "Update" : "Submit"}
        </button>
      </form>
      <h5 className="text-center my-4">Repair Man List</h5>
      <div className="table-responsive">
        <table className="table table-hover m-0">
          <thead>
            <tr>
              <th scope="col" className="text-center" style={{ width: "60px" }}>
                №
              </th>
              <th scope="col">Name</th>
              <th scope="col">Surname</th>
              <th scope="col">Phone Number</th>
              <th scope="col">Technical Area (az)</th>
              <th scope="col">Technical Area (en)</th>
              <th scope="col">Technical Area (ru)</th>
              <th scope="col">Image</th>
              <th
                scope="col"
                className="text-center"
                style={{ width: "100px" }}
              >
                Edit
              </th>
              <th
                scope="col"
                className="text-center"
                style={{ width: "100px" }}
              >
                Delete
              </th>
            </tr>
          </thead>
          <tbody>
            {repairMan.map((type, index) => {
              const base64String = `data:image/png;base64,${type.image.hashBase64}`;
              return (
                <tr key={type.id}>
                  <th scope="row" className="text-center">
                    {index + 1}
                  </th>
                  <td>{type.name}</td>
                  <td>{type.surname}</td>
                  <td>{type.phoneNumber}</td>
                  <td>{type.technicalArea.name.az}</td>
                  <td>{type.technicalArea.name.en}</td>
                  <td>{type.technicalArea.name.ru}</td>
                  <td>
                    <img
                      src={base64String}
                      width={150}
                      alt={type.name}
                      className="design-style-image"
                    />
                  </td>
                  <td className="text-center">
                    <button
                      className="btn btn-outline-warning btn-sm"
                      onClick={() => handleEdit(type)}
                    >
                      <i className="bi bi-pencil-square"></i>
                      Edit
                    </button>
                  </td>
                  <td className="text-center">
                    <button
                      className="btn btn-outline-danger btn-sm"
                      onClick={() => handleDelete(type.id)}
                    >
                      <i className="bi bi-trash3"></i>
                      Delete
                    </button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default RepairManManager;
