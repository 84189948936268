import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import AdminLogo from "../../../assets/images/AdminLogo.png";

const AdminLogin = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      // API'ye POST isteği gönderme
      const response = await axios.post(
        "https://api.emotix.dev/api/v1/e-prorab/auth/sign-in",
        { email, password }
      );
      if (response.data.user.id === 1 && response.data.token) {
        const token = response.data.token;
        localStorage.setItem("authToken", token);
        navigate("/dashboard");
      } else {
        alert("Login or Password is wrong!");
      }
    } catch (error) {
      console.error("Login error:", error);
      alert("Xəta baş verdi!");
    }
  };

  return (
    <div className="admin-login-section bg-light">
      <div className="container">
        <div className="row vh-100 justify-content-center align-items-center">
          <div className="col-md-6 col-sm-12 order-md-2">
            <div className="login-form-container p-4 rounded text-center">
              <img
                src={AdminLogo}
                alt="Admin Logo"
                className="mb-3"
                style={{ maxWidth: "150px" }}
              />
              <h2 className="mb-4">Welcome to Admin Dashboard</h2>
              <form onSubmit={handleLogin} className="d-flex flex-column gap-3">
                <div className="form-group">
                  <input
                    type="email"
                    className="form-control"
                    placeholder="Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                </div>
                <div className="form-group position-relative">
                  <input
                    type={showPassword ? "text" : "password"}
                    className="form-control"
                    placeholder="Password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                  />
                  <button
                    type="button"
                    className="btn position-absolute end-0 top-50 translate-middle-y text-secondary"
                    onClick={() => setShowPassword(!showPassword)}
                    style={{ padding: "0 12px" }}
                  >
                    <i
                      className={`fa-solid ${
                        showPassword ? "fa-eye-slash" : "fa-eye"
                      }`}
                    ></i>
                  </button>
                </div>
                <button type="submit" className="btn btn-primary w-100">
                  Login
                </button>
              </form>
            </div>
          </div>
          <div className="col-md-6 d-none d-md-block order-md-1 iframe-container">
            <iframe
              src="https://lottie.host/embed/eac67767-3e86-4194-a984-743c59773703/6d06szKY1b.lottie"
              style={{ width: "100%", height: "400px" }}
              frameBorder={0}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminLogin;
