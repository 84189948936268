import axios from "axios";
import React, { useEffect, useState } from "react";
import "./_RepairTariffManager.scss";
import swal from "sweetalert";

const RepairTariffManager = () => {
  const [repairTariffAdmin, setRepairTariffAdmin] = useState([]);
  const [repairTariff, setRepairTariff] = useState({
    name: { az: "", en: "", ru: "" },
    description: { az: "", en: "", ru: "" },
    minValue: null,
    maxValue: null,
    status: true,
  });
  const [isEditing, setIsEditing] = useState(false);
  const [editId, setEditId] = useState(null);
  const authToken = localStorage.getItem("authToken");

  useEffect(() => {
    fetchRepairTariffs();
  }, []);

  const fetchRepairTariffs = () => {
    axios
      .get("https://api.emotix.dev/api/v1/e-prorab/repair-tariffs/admin", {
        headers: { Authorization: `Bearer ${authToken}` },
      })
      .then((response) => setRepairTariffAdmin(response.data))
      .catch((error) => console.log(error));
  };

  const handleInput = (event) => {
    const { name, value, dataset } = event.target;
    const field = dataset.field;

    if (["az", "en", "ru"].includes(name)) {
      setRepairTariff((prevType) => ({
        ...prevType,
        [field]: { ...prevType[field], [name]: value },
      }));
    } else {
      const parsedValue =
        name === "minValue" || name === "maxValue" ? Number(value) : value;
      setRepairTariff((prevType) => ({ ...prevType, [name]: parsedValue }));
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const axiosMethod = isEditing ? axios.put : axios.post;
    const url = isEditing
      ? `https://api.emotix.dev/api/v1/e-prorab/repair-tariffs/${editId}`
      : "https://api.emotix.dev/api/v1/e-prorab/repair-tariffs";

    axiosMethod(url, repairTariff, {
      headers: { Authorization: `Bearer ${authToken}` },
    })
      .then(() => {
        setIsEditing(false);
        setEditId(null);
        setRepairTariff({
          name: { az: "", en: "", ru: "" },
          description: { az: "", en: "", ru: "" },
          minValue: null,
          maxValue: null,
          status: true,
        });
        fetchRepairTariffs();
        swal({
          title: "",
          text: "Repair tariff has been added",
          icon: "success",
          timer: 1500,
        });
      })
      .catch((error) => {
        console.log(error);
        swal({
          title: "",
          text: "Repair tariff has not been added",
          icon: "error",
          timer: 1500,
        });
      });
  };

  const handleEdit = (type) => {
    setRepairTariff({
      name: type.name,
      description: type.description,
      minValue: type.minValue,
      maxValue: type.maxValue,
      status: type.status,
    });
    setEditId(type.id);
    setIsEditing(true);
  };

  const handleDelete = (id) => {
    axios
      .delete(`https://api.emotix.dev/api/v1/e-prorab/repair-tariffs/${id}`, {
        headers: { Authorization: `Bearer ${authToken}` },
      })
      .then(() => fetchRepairTariffs())
      .catch((error) => console.log(error));
  };

  return (
    <div className="repair-tariff-manager">
      <h3>Repair Tariff Manager</h3>
      <form onSubmit={handleSubmit}>
        <div className="material-details">
          <h5 className="text-center">Repair Tariff Details</h5>

          {/* Name and Description Fields */}
          {["az", "en", "ru"].map((lang) => (
            <div key={lang} className="row g-3 mb-4">
              <div className="col-md-6">
                <label className="form-label">Name ({lang})</label>
                <input
                  className="form-control"
                  type="text"
                  value={repairTariff.name[lang]}
                  onChange={handleInput}
                  name={lang}
                  data-field="name"
                  required
                />
              </div>
              <div className="col-md-6">
                <label className="form-label">Description ({lang})</label>
                <input
                  className="form-control"
                  type="text"
                  value={repairTariff.description[lang]}
                  onChange={handleInput}
                  name={lang}
                  data-field="description"
                  required
                />
              </div>
            </div>
          ))}

          {/* Price Range */}
          <h5 className="text-center mt-4">Price Range</h5>
          <div className="row g-3">
            <div className="col-md-6">
              <label className="form-label">Min Price</label>
              <input
                className="form-control"
                type="number"
                value={repairTariff.minValue || ""}
                onChange={handleInput}
                name="minValue"
                required
              />
            </div>
            <div className="col-md-6">
              <label className="form-label">Max Price</label>
              <input
                className="form-control"
                type="number"
                value={repairTariff.maxValue || ""}
                onChange={handleInput}
                name="maxValue"
                required
              />
            </div>
          </div>
        </div>

        <button
          type="submit"
          className="btn btn-primary align-self-center mt-4"
        >
          {isEditing ? "Update Tariff" : "Add New Tariff"}
        </button>
      </form>

      <div className="table-section">
        <h5>Repair Tariff List</h5>
        <div className="table-responsive">
          <table className="table table-hover">
            <thead>
              <tr>
                <th scope="col" className="text-center">
                  №
                </th>
                <th scope="col">Name (az)</th>
                <th scope="col">Name (en)</th>
                <th scope="col">Name (ru)</th>
                <th scope="col">Description (az)</th>
                <th scope="col">Description (en)</th>
                <th scope="col">Description (ru)</th>
                <th scope="col">Min Price</th>
                <th scope="col">Max Price</th>
                <th scope="col" className="text-center">
                  Actions
                </th>
              </tr>
            </thead>
            <tbody>
              {repairTariffAdmin.map((type, index) => (
                <tr key={type.id}>
                  <th scope="row" className="text-center">
                    {index + 1}
                  </th>
                  <td>{type.name.az}</td>
                  <td>{type.name.en}</td>
                  <td>{type.name.ru}</td>
                  <td>{type.description.az}</td>
                  <td>{type.description.en}</td>
                  <td>{type.description.ru}</td>
                  <td>{type.minValue}</td>
                  <td>{type.maxValue}</td>
                  <td className="actions">
                    <button
                      className="btn btn-outline-warning"
                      onClick={() => handleEdit(type)}
                    >
                      <i className="bi bi-pencil-square"></i>
                      Edit
                    </button>
                    <button
                      className="btn btn-outline-danger"
                      onClick={() => handleDelete(type.id)}
                    >
                      <i className="bi bi-trash3"></i>
                      Delete
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default RepairTariffManager;
