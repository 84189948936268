import axios from "axios";
import React, { useEffect, useState } from "react";
import swal from "sweetalert";

const PropertyTypeManager = () => {
  const [propertyTypeAdmin, setPropertyTypeAdmin] = useState([]);
  const [propertyType, setPropertyType] = useState({
    name: { az: "", en: "", ru: "" },
    iconType: "",
    status: true,
  });
  const [isEditing, setIsEditing] = useState(false);
  const [editId, setEditId] = useState(null);

  const authToken = localStorage.getItem("authToken");

  useEffect(() => {
    if (!authToken) {
      console.error("No authentication token found");
      return;
    }
    fetchPropertyTypes();
  }, []);

  const fetchPropertyTypes = () => {
    if (!authToken) return;

    axios
      .get("https://api.emotix.dev/api/v1/e-prorab/property-types/admin", {
        headers: { Authorization: `Bearer ${authToken}` },
      })
      .then((response) => setPropertyTypeAdmin(response.data))
      .catch((error) => {
        if (error.response?.status === 401) {
          console.error("Authentication failed. Please log in again.");
          localStorage.removeItem("authToken");
        }
        console.error("Error fetching property types:", error);
      });
  };

  const handleInput = (event) => {
    const { name, value } = event.target;
    if (name === "az" || name === "en" || name === "ru") {
      setPropertyType((prevType) => ({
        ...prevType,
        name: { ...prevType.name, [name]: value },
      }));
    } else {
      setPropertyType((prevType) => ({ ...prevType, [name]: value }));
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (!authToken) {
      console.error("No authentication token found");
      return;
    }

    const apiUrl = isEditing
      ? `https://api.emotix.dev/api/v1/e-prorab/property-types/${editId}`
      : "https://api.emotix.dev/api/v1/e-prorab/property-types";

    const axiosMethod = isEditing ? axios.put : axios.post;

    axiosMethod(apiUrl, propertyType, {
      headers: { Authorization: `Bearer ${authToken}` },
    })
      .then(() => {
        setIsEditing(false);
        setEditId(null);
        setPropertyType({
          name: { az: "", en: "", ru: "" },
          iconType: "",
          status: true,
        });
        fetchPropertyTypes();
        swal({
          title: "",
          text: "Property type has been added",
          icon: "success",
          timer: 1500,
        });
      })
      .catch((error) => {
        console.error("Error submitting property type:", error);
        swal({
          title: "",
          text: "Property type has not been added",
          icon: "error",
          timer: 1500,
        });
      });
  };

  const handleEdit = (type) => {
    setPropertyType(type);
    setEditId(type.id);
    setIsEditing(true);
  };

  const handleDelete = (id) => {
    axios
      .delete(`https://api.emotix.dev/api/v1/e-prorab/property-types/${id}`, {
        headers: { Authorization: `Bearer ${authToken}` },
      })
      .then(() => fetchPropertyTypes())
      .catch((error) => console.log(error));
  };

  return (
    <div className="property-type-manager">
      <h3 className="text-center">Property Type Manager</h3>
      <form onSubmit={handleSubmit} className="d-flex flex-column gap-3">
        <div className="property-type-name">
          <h5 className="text-center mb-4">Property Name</h5>
          <div className="row g-3">
            <div className="col-md-4">
              <label className="form-label" htmlFor="az">
                Azerbaijani
              </label>
              <input
                className="form-control"
                type="text"
                value={propertyType.name.az}
                onChange={handleInput}
                name="az"
                required
              />
            </div>
            <div className="col-md-4">
              <label className="form-label" htmlFor="en">
                English
              </label>
              <input
                className="form-control"
                type="text"
                value={propertyType.name.en}
                onChange={handleInput}
                name="en"
                required
              />
            </div>
            <div className="col-md-4">
              <label className="form-label" htmlFor="ru">
                Russian
              </label>
              <input
                className="form-control"
                type="text"
                value={propertyType.name.ru}
                onChange={handleInput}
                name="ru"
                required
              />
            </div>
            <div className="col-12">
              <label className="form-label" htmlFor="iconType">
                Icon Type
              </label>
              <input
                className="form-control"
                type="text"
                value={propertyType.iconType}
                onChange={handleInput}
                name="iconType"
                required
              />
            </div>
          </div>
        </div>
        <button className="btn btn-primary align-self-center px-5">
          {isEditing ? "Save Changes" : "Add Property Type"}
        </button>
      </form>
      <h5 className="text-center mb-4">Property Types List</h5>
      <div className="table-responsive">
        <table className="table table-hover m-0">
          <thead>
            <tr>
              <th scope="col" className="text-center" style={{ width: "60px" }}>
                №
              </th>
              <th scope="col">Name (az)</th>
              <th scope="col">Name (en)</th>
              <th scope="col">Name (ru)</th>
              <th scope="col">Icon Type</th>
              <th
                scope="col"
                className="text-center"
                style={{ width: "100px" }}
              >
                Edit
              </th>
              <th
                scope="col"
                className="text-center"
                style={{ width: "100px" }}
              >
                Delete
              </th>
            </tr>
          </thead>
          <tbody>
            {propertyTypeAdmin.map((type, index) => (
              <tr key={type.id}>
                <th scope="row" className="text-center">
                  {index + 1}
                </th>
                <td>{type.name.az}</td>
                <td>{type.name.en}</td>
                <td>{type.name.ru}</td>
                <td>{type.iconType}</td>
                <td className="text-center">
                  <button
                    className="btn btn-outline-warning btn-sm"
                    onClick={() => handleEdit(type)}
                  >
                    <i className="bi bi-pencil-square"></i>
                    Edit
                  </button>
                </td>
                <td className="text-center">
                  <button
                    className="btn btn-outline-danger btn-sm"
                    onClick={() => handleDelete(type.id)}
                  >
                    <i className="bi bi-trash3"></i>
                    Delete
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default PropertyTypeManager;
