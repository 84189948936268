import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Header from "../../components/Header";
import { SquareLoader } from "react-spinners";
import { useTranslation } from "react-i18next";
const Maintenance = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  document.title = t("maintenance.0");
  const headerTitle = t("maintenance.1");
  const backwardNavigate = "/material";
  const [materialPrice, setMaterialPrice] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const { id } = useParams();
  const token = localStorage.getItem("token");
  const selectedRooms = JSON.parse(sessionStorage.getItem("roomValues")) || [];
  useEffect(() => {
    setIsLoading(true);
    axios
      .get(
        `https://api.emotix.dev/api/v1/e-prorab/repairs/general-price-consumption/${id}`
      )
      .then((response) => {
        setMaterialPrice(response.data);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [id]);
  const handleButtonClick = () => {
    if (token) {
      navigate(`/allmaterials/${id}`);
    } else {
      navigate("/signup");
    }
  };
  const handleRoom = () => {
    navigate("/dimensionroom");
  };
  const selectedPropertyType = JSON.parse(
    sessionStorage.getItem("selectedPropertyType")
  );
  const selectedHomeData = JSON.parse(sessionStorage.getItem("homeData"));
  return (
    <div className="maintenance-section mx-3">
      {isLoading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
            backgroundColor: "#f5f5f5",
          }}
        >
          <SquareLoader
            color="#8c920b"
            cssOverride={{
              position: "absolute",
              top: "45%",
              bottom: "45%",
            }}
            size={100}
            speedMultiplier={1}
          />
        </div>
      ) : (
        <>
          <Header
            title={headerTitle}
            showProfile={true}
            backwardNavigate={backwardNavigate}
          />
          <div className="top-section d-flex align-items-center justify-content-center">
            <h5 className="my-auto">{t("maintenance.0")}</h5>
          </div>
          <div className="home-data d-flex align-items-center justify-content-between gap-3 mb-5">
            <div className="left-section d-flex align-items-center gap-2">
              <i className={`${selectedPropertyType?.iconType}`}></i>
              <h4 className="m-0"> {selectedPropertyType?.name.text}</h4>
            </div>{" "}
            <div className="right-section align-items-center">
              <h4 htmlFor="area" className="m-0">
                {t("calculator.2")} :{" "}
                <span className="fs-6">
                  {selectedHomeData?.areaHouse || ""} m²
                </span>
              </h4>
              <h4 className="m-0">
                {t("calculator.3")} :{" "}
                <span className="fs-6">
                  {selectedHomeData?.heightWall || ""} m
                </span>
              </h4>
            </div>
          </div>
          <div className="top-middle-section">
            <h6 className="mb-3">
              Təmirə hesablanan otaqlar ({selectedRooms.length})
            </h6>
            <div className="selected-rooms gap-2">
              {selectedRooms.map((type, index) => (
                <button
                  className="btn room-btn"
                  key={index}
                  onClick={handleRoom}
                >
                  {type.name}
                </button>
              ))}
            </div>
            <p>
              Otaqlara klikləyərək detallı olaraq baxa və düzəliş edə
              bilərsiniz.
            </p>
            <hr />
          </div>
          <div className="middle-section mx-3">
            <div className="row gap-3">
              <h6 className="text-center">{t("maintenance.8")}</h6>
              <div className="col-12 second-raw">
                <div className="row">
                  <div className="col-6 first">{t("maintenance.4")}</div>
                  <div className="col-6 second">
                    {Math.ceil(materialPrice.materialsCost)}{" "}
                    {materialPrice.currency}
                  </div>
                </div>
              </div>
              <div className="col-12 third-raw">
                <div className="row">
                  <div className="col-6 first">{t("maintenance.5")}</div>
                  <div className="col-6 second">
                    {Math.ceil(materialPrice.serviceCost)}{" "}
                    {materialPrice.currency}
                  </div>
                </div>
              </div>
              <div className="col-12 fourth-raw">
                <div className="row">
                  <div className="col-6 first">{t("maintenance.6")}</div>
                  <div className="col-6 second">
                    {Math.ceil(materialPrice.generalCost)}{" "}
                    {materialPrice.currency}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="bottom-section">
            <div className="button-section">
              <button
                className="btn py-3 button-material global-button mb-3"
                onClick={handleButtonClick}
              >
                {t("maintenance.7")}
              </button>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default Maintenance;
