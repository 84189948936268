import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import iphone15Img from "../../assets/images/iPhone15.png";
import iphone15Img2 from "../../assets/images/iPhone15_2.png";
import iphone15Img3 from "../../assets/images/iPhone15_3.png";
import iphone15Img4 from "../../assets/images/iPhone15_4.png";
import { useTranslation } from "react-i18next";
import Header from "../../components/Header";
import { Link } from "react-router-dom";
const Onboarding = () => {
  document.title = "Giriş";
  const { t } = useTranslation();
  const backwardNavigate = "/";
  const sliderData = [
    {
      image: iphone15Img,
      title: t("calculatorOnboarding.0"),
      description: t("calculatorOnboarding.1"),
    },
    {
      image: iphone15Img2,
      title: t("catalogOnboarding.0"),
      description: t("catalogOnboarding.1"),
    },
    {
      image: iphone15Img3,
      title: t("monitoringOnboarding.0"),
      description: t("monitoringOnboarding.1"),
    },
    {
      image: iphone15Img4,
      title: t("foremanOnboarding.0"),
      description: t("foremanOnboarding.1"),
    },
  ];

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
  };

  return (
    <div
      style={{ width: "80%", margin: "0 auto" }}
      className="onboarding-section"
    >
      <Header backwardNavigate={backwardNavigate} />
      <Slider {...settings}>
        {sliderData.map((item, index) => (
          <div key={index}>
            <div className="img-section">
              {" "}
              <img className="img" src={item.image} alt={item.title} />
            </div>
            <div className="text-section">
              {" "}
              <h4 style={{ textAlign: "center", margin: "10px 0" }}>
                {item.title}
              </h4>
              <p style={{ textAlign: "center", color: "#555" }}>
                {item.description}
              </p>
            </div>
          </div>
        ))}
      </Slider>
      <div className="button-section">
        <Link to="/home">
          <button className="btn py-3">{t("foremanOnboarding.2")}</button>
        </Link>
      </div>
    </div>
  );
};

export default Onboarding;
