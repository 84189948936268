import React, { useState, useEffect } from "react";
import HeaderAccount from "../../../components/HeaderAccount";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { SquareLoader } from "react-spinners";
import axios from "axios";

const MyHome = () => {
  const { t } = useTranslation();
  const [properties, setProperties] = useState([]);
  const [loading, setLoading] = useState(true);
  document.title = t("myHome.0");
  const headerTitle = t("myHome.0");
  const backwardNavigate = "/account";
  const navigate = useNavigate();

  useEffect(() => {
    const fetchOrders = async () => {
      try {
        const token = localStorage.getItem("token");
        const storedUser = localStorage.getItem("user");

        if (!storedUser) {
          console.warn("User not found in localStorage");
          return;
        }

        const parsedUser = JSON.parse(storedUser);
        const userId = parsedUser.id;

        const response = await axios.get(
          "https://api.emotix.dev/api/v1/e-prorab/orders",
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        const filteredOrders = response.data.filter(
          (order) => order.user.id === userId
        );

        if (filteredOrders.length > 0) {
          const repairs = filteredOrders.map((order) => order.repair);
          const propertyList = repairs
            .map((o) => o.property)
            .filter((p) => !!p);
          setProperties(propertyList);
        }
      } catch (error) {
        console.error("Error fetching orders:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchOrders();
  }, []);

  const rooms = properties.flatMap((property) => property.rooms || []);
  const groupedRooms = rooms.reduce((acc, room) => {
    const spaceName = room.roomType.space.name.text;
    if (!acc[spaceName]) {
      acc[spaceName] = [];
    }
    acc[spaceName].push(room);
    return acc;
  }, {});

  const handlePropertyClick = (room) => {
    const property = properties.find((p) => p.rooms?.includes(room));
    if (property) {
      navigate(`/account/myhome/${property.id}`);
    }
  };

  if (loading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
          backgroundColor: "#f5f5f5",
        }}
      >
        <SquareLoader
          color="#8c920b"
          cssOverride={{
            position: "absolute",
            top: "45%",
            bottom: "45%",
          }}
          size={100}
          speedMultiplier={1}
        />
      </div>
    );
  }

  if (!properties || properties.length === 0) {
    return <div>No properties found</div>;
  }

  return (
    <div className="myhome-section px-3">
      <HeaderAccount title={headerTitle} backwardNavigate={backwardNavigate} />

      <div>
        {Object.entries(groupedRooms).map(([spaceName, roomList], index) => (
          <div key={index} className="myhome-section">
            <h4 className="mt-3">{spaceName}</h4>
            <div className="room-list">
              {roomList.map((room, roomIndex) => (
                <div
                  className="section d-flex align-items-center"
                  onClick={() => handlePropertyClick(room)}
                  style={{ cursor: "pointer" }}
                  key={roomIndex}
                >
                  <div className="left-section">
                    <i className={`${room.roomType.iconType}`}></i>
                  </div>
                  <div className="right-section d-flex align-items-center justify-content-between w-100">
                    <div className="property-title ms-3">
                      <h2>{room.roomType.name.text}</h2>
                    </div>
                    <div className="detail-section me-2">
                      <i className="fa-solid fa-chevron-right"></i>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default MyHome;
