import React, { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import Backward from "../assets/icons/Backward.svg";
const HeaderAccount = ({ title, backwardNavigate, passLink }) => {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <header className="mt-3 d-flex align-items-center justify-content-between mb-3">
      <Link to={backwardNavigate}>
        <img src={Backward} alt="eprorab logo" title="eprorab" />
      </Link>
      <div className="page-title">{title} </div>
      <div className="ms-4"></div>
    </header>
  );
};

export default HeaderAccount;
