import React, { useState, useEffect } from "react";
import axios from "axios";
import swal from "sweetalert";

const RepairTeamManager = () => {
  const [repairTeams, setRepairTeams] = useState({
    name: "",
    leader: "",
    repairmen: [],
  });
  const [repairTeamList, setRepairTeamList] = useState([]);
  const [leaders, setLeaders] = useState([]);
  const [repairmen, setRepairmen] = useState([]);
  const authToken = localStorage.getItem("authToken");
  const [isEditing, setIsEditing] = useState(false);
  const [editId, setEditId] = useState(null);

  useEffect(() => {
    fetchRepairTeam();
    fetchLeaders();
    fetchRepairmen();
  }, []);

  // Repair Team List
  const fetchRepairTeam = () => {
    axios
      .get("https://api.emotix.dev/api/v1/e-prorab/repair-team/admin", {
        headers: { Authorization: `Bearer ${authToken}` },
      })
      .then((response) => setRepairTeamList(response.data))
      .catch((error) => console.error("Failed to fetch repair teams:", error));
  };

  // Leader List
  const fetchLeaders = () => {
    axios
      .get("https://api.emotix.dev/api/v1/e-prorab/repairman/admin", {
        headers: { Authorization: `Bearer ${authToken}` },
      })
      .then((response) => setLeaders(response.data))
      .catch((error) => console.error("Failed to fetch leaders:", error));
  };

  // Repairmen List
  const fetchRepairmen = () => {
    axios
      .get("https://api.emotix.dev/api/v1/e-prorab/repairman/admin", {
        headers: { Authorization: `Bearer ${authToken}` },
      })
      .then((response) => setRepairmen(response.data))
      .catch((error) => console.error("Failed to fetch repairmen:", error));
  };

  // Form Submit
  const handleSubmit = (e) => {
    e.preventDefault();

    const payload = {
      name: repairTeams.name,
      leader: { id: repairTeams.leader },
      repairmen: repairTeams.repairmen.map((id) => ({ id: id, status: true })),
    };

    const url = isEditing
      ? `https://api.emotix.dev/api/v1/e-prorab/repair-team/${editId}`
      : "https://api.emotix.dev/api/v1/e-prorab/repair-team";

    const request = isEditing
      ? axios.put(url, payload, {
          headers: { Authorization: `Bearer ${authToken}` },
        })
      : axios.post(url, payload, {
          headers: { Authorization: `Bearer ${authToken}` },
        });

    request
      .then(() => {
        fetchRepairTeam();
        setRepairTeams({ name: "", leader: "", repairmen: [] });
        setIsEditing(false);
        setEditId(null);
        swal({
          title: "",
          text: "Repair team has been added",
          icon: "success",
          timer: 1500,
        });
      })
      .catch((error) => {
        console.error("Failed to save repair team:", error);
        swal({
          title: "",
          text: "Repair team has not been added",
          icon: "error",
          timer: 1500,
        });
      });
  };

  // Edit Handler
  const handleEdit = (team) => {
    setRepairTeams({
      name: team.name,
      leader: team.leader.id,
      repairmen: team.repairmen.map((r) => r.id),
    });
    setEditId(team.id);
    setIsEditing(true);
  };

  // Delete Handler
  const handleDelete = (id) => {
    axios
      .delete(`https://api.emotix.dev/api/v1/e-prorab/repair-team/${id}`, {
        headers: { Authorization: `Bearer ${authToken}` },
      })
      .then(() => fetchRepairTeam())
      .catch((error) => console.error("Failed to delete repair team:", error));
  };

  // Checkbox Change
  const handleRepairmenChange = (id) => {
    setRepairTeams((prev) => {
      if (prev.repairmen.includes(id)) {
        return { ...prev, repairmen: prev.repairmen.filter((r) => r !== id) };
      } else {
        return { ...prev, repairmen: [...prev.repairmen, id] };
      }
    });
  };

  return (
    <div className="repairTeams-manager">
      <h3 className="text-center">Repair Team Manager</h3>
      <form onSubmit={handleSubmit} className="d-flex flex-column gap-3">
        {/* Name Input */}
        <div>
          <label className="form-label">Name</label>
          <input
            type="text"
            value={repairTeams.name}
            onChange={(e) =>
              setRepairTeams({ ...repairTeams, name: e.target.value })
            }
            required
            className="form-control"
          />
        </div>

        {/* Leader Select */}
        <div>
          <label className="form-label">Leader</label>
          <select
            value={repairTeams.leader}
            onChange={(e) =>
              setRepairTeams({ ...repairTeams, leader: e.target.value })
            }
            className="form-control"
            required
          >
            <option value="">Select Leader</option>
            {leaders.map((leader) => (
              <option key={leader.id} value={leader.id}>
                {leader.name} {leader.surname}
              </option>
            ))}
          </select>
        </div>

        {/* Repairmen Checkbox */}
        <div>
          <label className="form-label">Repairmen</label>
          <div className="d-flex flex-wrap gap-2">
            {repairmen.map((repairman) => (
              <div key={repairman.id} className="form-check">
                <input
                  type="checkbox"
                  value={repairman.id}
                  checked={repairTeams.repairmen.includes(repairman.id)}
                  onChange={() => handleRepairmenChange(repairman.id)}
                  className="form-check-input"
                />
                <label className="form-check-label">
                  {repairman.name} {repairman.surname}
                </label>
              </div>
            ))}
          </div>
        </div>

        {/* Submit Button */}
        <button
          type="submit"
          className="btn btn-primary align-self-center px-5"
        >
          {isEditing ? "Save Changes" : "Add Repair Team"}
        </button>
      </form>

      {/* Repair Team Table */}
      <h5 className="text-center mb-4">Repair Teams List</h5>
      <div className="table-responsive">
        <table className="table table-hover m-0">
          <thead>
            <tr>
              <th scope="col" className="text-center" style={{ width: "60px" }}>
                №
              </th>
              <th scope="col">Name</th>
              <th scope="col">Leader</th>
              <th scope="col">Repairmen</th>
              <th
                scope="col"
                className="text-center"
                style={{ width: "100px" }}
              >
                Edit
              </th>
              <th
                scope="col"
                className="text-center"
                style={{ width: "100px" }}
              >
                Delete
              </th>
            </tr>
          </thead>
          <tbody>
            {repairTeamList.map((team, index) => (
              <tr key={team.id}>
                <th scope="row" className="text-center">
                  {index + 1}
                </th>
                <td>{team.name}</td>
                <td>
                  {team.leader.name} {team.leader.surname}
                </td>
                <td>
                  {team.repairmen.map((rm) => (
                    <p key={rm.id} className="m-0">
                      {rm.name} {rm.surname}
                    </p>
                  ))}
                </td>
                <td className="text-center">
                  <button
                    className="btn btn-outline-warning btn-sm"
                    onClick={() => handleEdit(team)}
                  >
                    <i className="bi bi-pencil-square"></i>
                    Edit
                  </button>
                </td>
                <td className="text-center">
                  <button
                    className="btn btn-outline-danger btn-sm"
                    onClick={() => handleDelete(team.id)}
                  >
                    <i className="bi bi-trash3"></i>
                    Delete
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default RepairTeamManager;
