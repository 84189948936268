import React, { useState, useEffect } from "react";
import axios from "axios";
import swal from "sweetalert";

const StoreManager = () => {
  const [store, setStore] = useState({
    name: { az: "", en: "", ru: "" },
    phoneNumber: "",
    image: {
      id: null,
      status: true,
    },
    status: true,
    materials: [],
  });
  const [stores, setStores] = useState([]);
  const [materials, setMaterials] = useState([]);
  const [categories, setCategories] = useState([]);
  const [repairStyles, setRepairStyles] = useState([]);
  const [categoryId, setCategoryId] = useState(null);
  const [repairStyleId, setRepairStyleId] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [editId, setEditId] = useState(null);

  const authToken = localStorage.getItem("authToken");

  useEffect(() => {
    fetchCategories();
    fetchRepairStyles();
    fetchStores();
  }, []);

  const fetchMaterials = (categoryId, repairStyleId) => {
    if (categoryId && repairStyleId) {
      axios
        .get(
          `https://api.emotix.dev/api/v1/e-prorab/materials/filter?categoryId=${categoryId}&repairStyleId=${repairStyleId}`,
          {
            headers: { Authorization: `Bearer ${authToken}` },
          }
        )
        .then((response) => {
          setMaterials(response.data);
        })
        .catch((error) => console.error("Failed to fetch materials:", error));
    }
  };

  const fetchCategories = () => {
    axios
      .get("https://api.emotix.dev/api/v1/e-prorab/material-categories", {
        headers: { Authorization: `Bearer ${authToken}` },
      })
      .then((response) => {
        setCategories(response.data);
      })
      .catch((error) => console.error("Failed to fetch categories:", error));
  };

  const fetchRepairStyles = () => {
    axios
      .get("https://api.emotix.dev/api/v1/e-prorab/repair-styles", {
        headers: { Authorization: `Bearer ${authToken}` },
      })
      .then((response) => {
        setRepairStyles(response.data);
      })
      .catch((error) => console.error("Failed to fetch repair styles:", error));
  };

  const fetchStores = () => {
    axios
      .get("https://api.emotix.dev/api/v1/e-prorab/stores/admin", {
        headers: { Authorization: `Bearer ${authToken}` },
      })
      .then((response) => {
        setStores(response.data);
      })
      .catch((error) => console.error("Failed to fetch stores:", error));
  };

  const handleInput = (event) => {
    const { name, value, dataset } = event.target;
    const field = dataset.field;

    if (["az", "en", "ru"].includes(name)) {
      setStore((prevType) => ({
        ...prevType,
        [field]: { ...prevType[field], [name]: value },
      }));
    } else {
      setStore((prevType) => ({ ...prevType, [name]: value }));
    }
  };

  const uploadImage = (file) => {
    const formData = new FormData();
    formData.append("file", file);

    return axios.post(
      "https://api.emotix.dev/api/v1/e-prorab/mono-files/upload",
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${authToken}`,
        },
      }
    );
  };

  const handleImageUpload = (event) => {
    const fileInput = event.target;
    if (fileInput && fileInput.files && fileInput.files[0]) {
      const file = fileInput.files[0];
      uploadImage(file)
        .then((response) => {
          const imageId = response.data.id;
          setStore((prevStore) => ({
            ...prevStore,
            image: { ...prevStore.image, id: imageId },
          }));
        })
        .catch((error) => console.log("Failed to upload image:", error));
    } else {
      console.log("No file selected");
    }
  };

  const handleMaterialSelect = (materialId) => {
    const isSelected = store.materials.some(
      (material) => material.id === materialId
    );

    if (isSelected) {
      setStore((prevStore) => ({
        ...prevStore,
        materials: prevStore.materials.filter(
          (material) => material.id !== materialId
        ),
      }));
    } else {
      setStore((prevStore) => ({
        ...prevStore,
        materials: [...prevStore.materials, { id: materialId, status: true }],
      }));
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const storeData = { ...store };

    const url = isEditing
      ? `https://api.emotix.dev/api/v1/e-prorab/stores/${editId}`
      : "https://api.emotix.dev/api/v1/e-prorab/stores";

    const request = isEditing
      ? axios.put(url, storeData, {
          headers: { Authorization: `Bearer ${authToken}` },
        })
      : axios.post(url, storeData, {
          headers: { Authorization: `Bearer ${authToken}` },
        });

    request
      .then(() => {
        setIsEditing(false);
        setEditId(null);
        setStore({
          name: { az: "", en: "", ru: "" },
          phoneNumber: "",
          image: { id: null, status: true },
          materials: [],
          status: true,
        });
        fetchStores();
        swal({
          title: "",
          text: "Store has been added",
          icon: "success",
          timer: 1500,
        });
      })
      .catch((error) => {
        console.error("Failed to submit store:", error);
        swal({
          title: "",
          text: "Store has not been added",
          icon: "error",
          timer: 1500,
        });
      });
  };

  const handleEdit = (store) => {
    setIsEditing(true);
    setEditId(store.id);
    setStore(store);
  };

  const handleDelete = (storeId) => {
    axios
      .delete(`https://api.emotix.dev/api/v1/e-prorab/stores/${storeId}`, {
        headers: { Authorization: `Bearer ${authToken}` },
      })
      .then(() => {
        fetchStores();
        alert("Store deleted successfully!");
      })
      .catch((error) => {
        console.error("Failed to delete store:", error);
      });
  };

  return (
    <div className="store-manager">
      <h3 className="text-center">Store Manager</h3>
      <form onSubmit={handleSubmit} className="d-flex flex-column gap-3">
        <div className="store-name">
          <h5 className="text-center mb-4">Store Information</h5>
          <div className="row g-3">
            <div className="col-md-4">
              <label className="form-label" htmlFor="az">
                Azerbaijani
              </label>
              <input
                className="form-control"
                type="text"
                value={store.name.az}
                onChange={handleInput}
                name="az"
                data-field="name"
                required
              />
            </div>
            <div className="col-md-4">
              <label className="form-label" htmlFor="en">
                English
              </label>
              <input
                className="form-control"
                type="text"
                value={store.name.en}
                onChange={handleInput}
                name="en"
                data-field="name"
                required
              />
            </div>
            <div className="col-md-4">
              <label className="form-label" htmlFor="ru">
                Russian
              </label>
              <input
                className="form-control"
                type="text"
                value={store.name.ru}
                onChange={handleInput}
                name="ru"
                data-field="name"
                required
              />
            </div>
            <div className="col-12">
              <label className="form-label" htmlFor="phoneNumber">
                Phone Number
              </label>
              <input
                className="form-control"
                type="text"
                name="phoneNumber"
                value={store.phoneNumber}
                onChange={handleInput}
                required
              />
            </div>
            <div className="col-12">
              <label className="form-label">Store Image</label>
              <input
                className="form-control"
                type="file"
                onChange={handleImageUpload}
                required={!isEditing}
              />
            </div>
          </div>
        </div>

        <div className="material-selection">
          <h5 className="text-center mb-4">Material Selection</h5>
          <div className="row g-3">
            <div className="col-md-6">
              <select
                className="form-select"
                onChange={(e) => {
                  setCategoryId(e.target.value);
                  fetchMaterials(e.target.value, repairStyleId);
                }}
              >
                <option value="">Select Category</option>
                {categories.map((category) => (
                  <option key={category.id} value={category.id}>
                    {category.name.text}
                  </option>
                ))}
              </select>
            </div>

            <div className="col-md-6">
              <select
                className="form-select"
                onChange={(e) => {
                  setRepairStyleId(e.target.value);
                  fetchMaterials(categoryId, e.target.value);
                }}
              >
                <option value="">Select Repair Style</option>
                {repairStyles.map((repairStyle) => (
                  <option key={repairStyle.id} value={repairStyle.id}>
                    {repairStyle.name.text}
                  </option>
                ))}
              </select>
            </div>
          </div>

          <div className="materials-list mt-4">
            <h6 className="text-center mb-3">Available Materials</h6>
            <div className="row g-2">
              {materials.map((material) => (
                <div key={material.id} className="col-md-4">
                  <button
                    className={`btn w-100 ${
                      store.materials.some((m) => m.id === material.id)
                        ? "btn-primary"
                        : "btn-outline-primary"
                    }`}
                    type="button"
                    onClick={() => handleMaterialSelect(material.id)}
                  >
                    {material.name.az}
                  </button>
                </div>
              ))}
            </div>
          </div>
        </div>

        <button className="btn btn-primary align-self-center px-5">
          {isEditing ? "Save Changes" : "Add Store"}
        </button>
      </form>

      <h5 className="text-center mb-4">Stores List</h5>
      <div className="table-responsive">
        <table className="table table-hover m-0">
          <thead>
            <tr>
              <th scope="col" className="text-center" style={{ width: "60px" }}>
                №
              </th>
              <th scope="col">Name (az)</th>
              <th scope="col">Name (en)</th>
              <th scope="col">Name (ru)</th>
              <th scope="col">Phone Number</th>
              <th scope="col">Image</th>
              <th scope="col">Materials</th>
              <th
                scope="col"
                className="text-center"
                style={{ width: "100px" }}
              >
                Edit
              </th>
              <th
                scope="col"
                className="text-center"
                style={{ width: "100px" }}
              >
                Delete
              </th>
            </tr>
          </thead>
          <tbody>
            {stores.map((type, index) => {
              const base64String = `data:image/png;base64,${type.image.hashBase64}`;
              return (
                <tr key={type.id}>
                  <th scope="row" className="text-center">
                    {index + 1}
                  </th>
                  <td>{type.name.az}</td>
                  <td>{type.name.en}</td>
                  <td>{type.name.ru}</td>
                  <td>{type.phoneNumber}</td>
                  <td>
                    <img
                      src={base64String}
                      width={100}
                      alt={type.name.en}
                      className="img-thumbnail"
                    />
                  </td>
                  <td>
                    {materials.map((type, index) => (
                      <span key={index} className="badge bg-primary me-1">
                        {type.name.az}
                      </span>
                    ))}
                  </td>
                  <td className="text-center">
                    <button
                      className="btn btn-outline-warning btn-sm"
                      onClick={() => handleEdit(type)}
                    >
                      <i className="bi bi-pencil-square"></i>
                      Edit
                    </button>
                  </td>
                  <td className="text-center">
                    <button
                      className="btn btn-outline-danger btn-sm"
                      onClick={() => handleDelete(type.id)}
                    >
                      <i className="bi bi-trash3"></i>
                      Delete
                    </button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default StoreManager;
