import axios from "axios";
import React, { useEffect, useState } from "react";
import swal from "sweetalert";

const MaterialCategoryManager = () => {
  const [materialCategoryAdmin, setMaterialCategoryAdmin] = useState([]);
  const [materialCategory, setMaterialCategory] = useState({
    name: { az: "", en: "", ru: "" },
    status: true,
  });
  const [isEditing, setIsEditing] = useState(false);
  const [editId, setEditId] = useState(null);

  useEffect(() => {
    fetchMaterialCategory();
  }, []);
  const authToken = localStorage.getItem("authToken");
  const fetchMaterialCategory = () => {
    axios
      .get("https://api.emotix.dev/api/v1/e-prorab/material-categories/admin", {
        headers: { Authorization: `Bearer ${authToken}` },
      })
      .then((response) => setMaterialCategoryAdmin(response.data))
      .catch((error) => console.log(error));
  };

  const handleInput = (event) => {
    const { name, value, dataset } = event.target;
    const field = dataset.field;

    if (["az", "en", "ru"].includes(name)) {
      setMaterialCategory((prevType) => ({
        ...prevType,
        [field]: { ...prevType[field], [name]: value },
      }));
    } else {
      setMaterialCategory((prevType) => ({ ...prevType, [name]: value }));
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const axiosMethod = isEditing ? axios.put : axios.post;
    const url = isEditing
      ? `https://api.emotix.dev/api/v1/e-prorab/material-categories/${editId}`
      : "https://api.emotix.dev/api/v1/e-prorab/material-categories";

    axiosMethod(url, materialCategory, {
      headers: { Authorization: `Bearer ${authToken}` },
    })
      .then(() => {
        setIsEditing(false);
        setEditId(null);
        setMaterialCategory({
          name: { az: "", en: "", ru: "" },
          status: true,
        });
        fetchMaterialCategory();
        swal({
          title: "",
          text: "Material category has been added",
          icon: "success",
          timer: 1500,
        });
      })
      .catch((error) => {
        console.log(error);
        swal({
          title: "",
          text: "Material category has not been added",
          icon: "error",
          timer: 1500,
        });
      });
  };

  const handleEdit = (type) => {
    setMaterialCategory(type);
    setEditId(type.id);
    setIsEditing(true);
  };

  const handleDelete = (id) => {
    axios
      .delete(
        `https://api.emotix.dev/api/v1/e-prorab/material-categories/${id}`,
        {
          headers: { Authorization: `Bearer ${authToken}` },
        }
      )
      .then(() => fetchMaterialCategory())
      .catch((error) => console.log(error));
  };

  return (
    <div className="material-category-manager">
      <h3 className="text-center">Material Category Manager</h3>
      <form onSubmit={handleSubmit} className="d-flex flex-column gap-3">
        <div className="material-category-name">
          <h5 className="text-center mb-4">Material Category Name</h5>
          <div className="row g-3">
            <div className="col-md-4">
              <label className="form-label" htmlFor="az">
                Azerbaijani
              </label>
              <input
                className="form-control"
                type="text"
                value={materialCategory.name.az}
                onChange={handleInput}
                name="az"
                data-field="name"
                required
              />
            </div>
            <div className="col-md-4">
              <label className="form-label" htmlFor="en">
                English
              </label>
              <input
                className="form-control"
                type="text"
                value={materialCategory.name.en}
                onChange={handleInput}
                name="en"
                data-field="name"
                required
              />
            </div>
            <div className="col-md-4">
              <label className="form-label" htmlFor="ru">
                Russian
              </label>
              <input
                className="form-control"
                type="text"
                value={materialCategory.name.ru}
                onChange={handleInput}
                name="ru"
                data-field="name"
                required
              />
            </div>
          </div>
        </div>
        <button className="btn btn-primary align-self-center px-5">
          {isEditing ? "Save Changes" : "Add Material Category"}
        </button>
      </form>

      <h5 className="text-center mb-4">Material Categories List</h5>
      <div className="table-responsive">
        <table className="table table-hover m-0">
          <thead>
            <tr>
              <th scope="col" className="text-center" style={{ width: "60px" }}>
                №
              </th>
              <th scope="col">Name (az)</th>
              <th scope="col">Name (en)</th>
              <th scope="col">Name (ru)</th>
              <th
                scope="col"
                className="text-center"
                style={{ width: "100px" }}
              >
                Edit
              </th>
              <th
                scope="col"
                className="text-center"
                style={{ width: "100px" }}
              >
                Delete
              </th>
            </tr>
          </thead>
          <tbody>
            {materialCategoryAdmin.map((type, index) => (
              <tr key={type.id}>
                <th scope="row" className="text-center">
                  {index + 1}
                </th>
                <td>{type.name.az}</td>
                <td>{type.name.en}</td>
                <td>{type.name.ru}</td>
                <td className="text-center">
                  <button
                    className="btn btn-outline-warning btn-sm"
                    onClick={() => handleEdit(type)}
                  >
                    <i className="bi bi-pencil-square"></i>
                    Edit
                  </button>
                </td>
                <td className="text-center">
                  <button
                    className="btn btn-outline-danger btn-sm"
                    onClick={() => handleDelete(type.id)}
                  >
                    <i className="bi bi-trash3"></i>
                    Delete
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default MaterialCategoryManager;
