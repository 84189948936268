import React, { useState, useEffect, useRef } from "react";
import RoomBasic from "../../assets/images/RoomBasic.png";
import DefaultCeiling from "../../assets/images/DefaultCeiling.png";
import DefaultWall from "../../assets/images/DefaultWall.png";
import DefaultFloor from "../../assets/images/DefaultFloor.png";
import { useNavigate } from "react-router-dom";
import RoomDropdown from "../../components/RoomDropdown/RoomDropdown";
import Header from "../../components/Header";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { SquareLoader } from "react-spinners";

const Material = () => {
  const { t } = useTranslation();
  document.title = t("material.0");
  const headerTitle = t("material.0");
  const navigate = useNavigate();
  const backwardNavigate = "/dimensionroom";
  const [activeSection, setActiveSection] = useState(null);
  const [ceilingImage, setCeilingImage] = useState(DefaultCeiling);
  const [wallImage, setWallImage] = useState(DefaultWall);
  const [floorImage, setFloorImage] = useState(DefaultFloor);
  const [showFullRoomImage, setShowFullRoomImage] = useState(false);
  const [selectedMaterials, setSelectedMaterials] = useState(() => {
    // Initialize from sessionStorage if exists
    const savedMaterials = sessionStorage.getItem("selectedMaterial");
    if (savedMaterials) {
      const parsed = JSON.parse(savedMaterials);
      return (
        parsed.roomTypes?.reduce((acc, room) => {
          acc[room.roomType] = {
            roomType: room.roomType,
            materials: room.materials,
          };
          return acc;
        }, {}) || {}
      );
    }
    return {};
  });
  const [roomImage, setRoomImage] = useState(RoomBasic);
  const dropdownRef = useRef(null);
  const [materialCategory, setMaterialCategory] = useState([]);
  const [material, setMaterial] = useState([]);
  const [loading, setLoading] = useState(false);
  const [hasLoadedOnce, setHasLoadedOnce] = useState(false);
  const selectedRepairTariff =
    JSON.parse(sessionStorage.getItem("selectedRepairTariff")) || {};
  const selectedRoomTypes =
    JSON.parse(sessionStorage.getItem("roomValues")) || [];
  const [selectedRoom, setSelectedRoom] = useState(() => {
    // Initialize selected room from sessionStorage if exists
    const savedMaterials = sessionStorage.getItem("selectedMaterial");
    if (savedMaterials) {
      const parsed = JSON.parse(savedMaterials);
      return parsed.roomTypes?.[0]?.roomType || "Qonaq otağı";
    }
    return "Qonaq otağı";
  });

  useEffect(() => {
    axios
      .get("https://api.emotix.dev/api/v1/e-prorab/material-categories")
      .then((response) => {
        setMaterialCategory(response.data);

        // After getting categories, fetch initial materials if repair style exists
        const selectedRepairStyle = JSON.parse(
          sessionStorage.getItem("selectedRepairStyle")
        );
        const repairStyleId = selectedRepairStyle?.id;

        if (repairStyleId && response.data?.[0]?.id) {
          setLoading(true);
          fetchMaterials(response.data[0].id, repairStyleId);
          setTimeout(() => {
            setLoading(false);
          }, 1000);
        }
      })
      .catch((error) => {
        console.error("Error fetching material categories:", error);
        setMaterialCategory([]);
      });
  }, []);

  useEffect(() => {
    if (activeSection && !hasLoadedOnce) {
      setLoading(true);
      setTimeout(() => {
        setLoading(false);
        setHasLoadedOnce(true);
      }, 5000);
    }
  }, [activeSection, hasLoadedOnce]);

  const fetchMaterials = (categoryId, repairStyleId) => {
    axios
      .get(
        `https://api.emotix.dev/api/v1/e-prorab/materials/filter?categoyId=${categoryId}&repairStyleId=${repairStyleId}`
      )
      .then((response) => {
        setMaterial(response.data);
      })
      .catch((error) => {
        console.error("Error fetching materials:", error);
        setMaterial([]);
      });
  };

  const handleSectionClick = (categoryId) => {
    const selectedRepairStyle = JSON.parse(
      sessionStorage.getItem("selectedRepairStyle")
    );
    const repairStyleId = selectedRepairStyle?.id;

    if (repairStyleId) {
      fetchMaterials(categoryId, repairStyleId);
      setActiveSection(categoryId === activeSection ? null : categoryId);

      // Safari için height hesaplamasını düzenleme
      requestAnimationFrame(() => {
        const dropdownHeight = Math.max(300, window.innerHeight * 0.3);
        document.documentElement.style.setProperty(
          "--dropdown-height",
          `${dropdownHeight}px`
        );
      });
    } else {
      console.error("Repair style ID not found in local storage.");
    }
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setActiveSection(null);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleRoomChange = (newRoom) => {
    setSelectedRoom(newRoom.name);
    // Save to sessionStorage
    sessionStorage.setItem("currentSelectedRoom", newRoom.name);
  };

  const handleMaterialSelect = (categoryId, item) => {
    setSelectedMaterials((prevSelected) => {
      const newSelected = { ...prevSelected };

      if (!newSelected[selectedRoom]) {
        newSelected[selectedRoom] = { roomType: selectedRoom, materials: [] };
      }

      const roomMaterials = newSelected[selectedRoom].materials;

      const existingMaterialIndex = roomMaterials.findIndex(
        (mat) => mat.categoryId === categoryId
      );

      if (existingMaterialIndex !== -1) {
        if (roomMaterials[existingMaterialIndex].id === item.id) {
          roomMaterials.splice(existingMaterialIndex, 1);
          // Reset the image to default when material is deselected
          if (categoryId === 1) setCeilingImage(DefaultCeiling);
          else if (categoryId === 2) setWallImage(DefaultWall);
          else if (categoryId === 3) setFloorImage(DefaultFloor);
          else setShowFullRoomImage(false);
        } else {
          roomMaterials[existingMaterialIndex] = {
            categoryId,
            id: item.id,
            price:
              selectedRepairTariff.id === 1
                ? item.lowPrice
                : selectedRepairTariff.id === 2
                ? item.midPrice
                : selectedRepairTariff.id === 3
                ? item.highPrice
                : item.price,
            currency: item.currency,
          };
        }
      } else {
        roomMaterials.push({
          categoryId,
          id: item.id,
          price:
            selectedRepairTariff.id === 1
              ? item.lowPrice
              : selectedRepairTariff.id === 2
              ? item.midPrice
              : selectedRepairTariff.id === 3
              ? item.highPrice
              : item.price,
          currency: item.currency,
        });
      }

      // Create the data structure for sessionStorage
      const newData = {
        roomTypes: Object.values(newSelected).map((roomDetails) => ({
          roomType: roomDetails.roomType,
          materials: roomDetails.materials,
        })),
      };

      // Save to sessionStorage
      sessionStorage.setItem("selectedMaterial", JSON.stringify(newData));

      if (item.imageOnRoom?.hashBase64) {
        const base64String = `data:image/png;base64,${item.imageOnRoom.hashBase64}`;

        // Update specific section based on category ID
        if (categoryId === 1) {
          setCeilingImage(base64String);
          setShowFullRoomImage(false);
        } else if (categoryId === 2) {
          setWallImage(base64String);
          setShowFullRoomImage(false);
        } else if (categoryId === 3) {
          setFloorImage(base64String);
          setShowFullRoomImage(false);
        } else {
          setRoomImage(base64String);
          setShowFullRoomImage(true);
        }
      }

      return newSelected;
    });

    setActiveSection(null);
  };

  const sendRepairStyleID = parseInt(
    JSON.parse(sessionStorage.getItem("selectedRepairStyle"))?.id
  );
  const sendPropertyTypeID = parseInt(
    JSON.parse(sessionStorage.getItem("selectedPropertyType"))?.id
  );
  const sendRepairTariffID = parseInt(
    JSON.parse(sessionStorage.getItem("selectedRepairTariff"))?.id
  );
  const sendPropertyStatusID = parseInt(
    JSON.parse(sessionStorage.getItem("selectedPropertyStatuses"))?.id
  );
  const sendHomeData = JSON.parse(sessionStorage.getItem("homeData"));
  const sendRoom = JSON.parse(sessionStorage.getItem("roomValues"));

  const data = {
    repairStyle: {
      id: sendRepairStyleID,
    },
    repairTariff: {
      id: sendRepairTariffID,
    },
    property: {
      propertyType: {
        id: sendPropertyTypeID,
      },
      propertyStatus: {
        id: sendPropertyStatusID,
      },
      area: parseInt(sendHomeData?.areaHouse),
      height: parseInt(sendHomeData?.heightWall),
      roomCount: parseInt(sendHomeData?.roomCount),
      doorCount: parseInt(sendHomeData?.roomDoorCount),
      rooms: sendRoom.map((room) => {
        const roomMaterials = selectedMaterials[room.name]?.materials || [];
        return {
          roomType: {
            id: parseInt(room.originalId),
          },
          width: parseInt(room.width),
          length: parseInt(room.length),
          area: parseInt(room.area),
          height: parseInt(room.height),
          roomComponents: roomMaterials.map((material) => ({
            materialCategory: {
              id: parseInt(material.categoryId),
            },
            material: {
              id: parseInt(material.id),
            },
            status: true,
          })),
          status: true,
        };
      }),
      status: true,
    },
    status: true,
  };

  const postRepairData = () => {
    const allRoomsHaveMaterials = selectedRoomTypes.every((room) => {
      const roomMaterials = selectedMaterials[room.name]?.materials || [];
      return roomMaterials.length > 0;
    });

    if (allRoomsHaveMaterials) {
      axios
        .post("https://api.emotix.dev/api/v1/e-prorab/repairs", data)
        .then((response) => {
          const repairId = response.data.id;
          localStorage.setItem("repairId", repairId);
          navigate(`/maintenance/${repairId}`);
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      return;
    }
  };

  return (
    <div className="material-section px-3">
      <Header
        title={headerTitle}
        showProfile={true}
        backwardNavigate={backwardNavigate}
      />
      <>
        <div className="top-section">
          <div className="room-handle-section mb-4">
            <RoomDropdown
              onChange={handleRoomChange}
              selectedRoomTypes={selectedRoomTypes}
            />
          </div>
          <div className="image-on-room-section">
            {showFullRoomImage ? (
              <img src={roomImage} alt="Room" className="full-room-image" />
            ) : (
              <>
                <div className="ceiling-section">
                  <img src={ceilingImage} alt="Ceiling" />
                </div>
                <div className="wall-section">
                  <img src={wallImage} alt="Wall" />
                </div>
                <div className="floor-section">
                  <img src={floorImage} alt="Floor" />
                </div>
              </>
            )}
          </div>
        </div>
        <div className="middle-section">
          <div className="material-category-buttons gap-3">
            {materialCategory?.map((type) => (
              <button
                key={type.id}
                className="btn"
                onClick={() => handleSectionClick(type.id)}
                style={{
                  cursor: !selectedRoom ? "not-allowed" : "pointer",
                  opacity: !selectedRoom ? 0.5 : 1,
                }}
              >
                {type.name.text}
              </button>
            ))}
          </div>
        </div>
        {activeSection && (
          <div ref={dropdownRef} className="dropdown-section active">
            <div className="dropdown-content slide-up">
              {loading ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "30vh", // Dropdown height'ı ile aynı
                    backgroundColor: "transparent",
                  }}
                >
                  <SquareLoader color="#8c920b" size={50} speedMultiplier={1} />
                </div>
              ) : material.filter((item) =>
                  item.categories?.some(
                    (category) => category.id === activeSection
                  )
                ).length > 0 ? (
                material
                  .filter((item) =>
                    item.categories?.some(
                      (category) => category.id === activeSection
                    )
                  )
                  .map((item) => {
                    const base64String = `data:image/png;base64,${item.image.hashBase64}`;

                    const price =
                      selectedRepairTariff.id === 1
                        ? item.lowPrice
                        : selectedRepairTariff.id === 2
                        ? item.midPrice
                        : selectedRepairTariff.id === 3
                        ? item.highPrice
                        : item.price;

                    return (
                      <button
                        key={item.id}
                        className={`btn ${
                          selectedMaterials[selectedRoom]?.materials.some(
                            (mat) => mat.id === item.id
                          )
                            ? "selected"
                            : ""
                        }`}
                        onClick={() =>
                          handleMaterialSelect(activeSection, item)
                        }
                      >
                        <div className="material-box">
                          <img src={base64String} alt="Material" />
                          <h5>{item.title}</h5>
                          <p>
                            1 kv.m. - {price} {item.currency}
                          </p>
                        </div>
                      </button>
                    );
                  })
              ) : null}
            </div>
          </div>
        )}
        <div className="button-section">
          <button className="btn py-3 global-button" onClick={postRepairData}>
            {t("material.2")}
          </button>
        </div>
        {/* Selected Materials Section */}
        <div className="selected-materials-section">
          <h3 className="selected-materials-title">Added Materials</h3>
          <div className="room-materials">
            <h4 className="room-name">{selectedRoom}</h4>
            {loading ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  padding: "20px",
                }}
              >
                <SquareLoader color="#8c920b" size={50} speedMultiplier={1} />
              </div>
            ) : (
              <div className="selected-materials-grid">
                {selectedMaterials[selectedRoom]?.materials.map(
                  (selectedMaterial) => {
                    const materialDetails = material.find(
                      (m) => m.id === selectedMaterial.id
                    );
                    if (!materialDetails) return null;

                    const base64String = `data:image/png;base64,${materialDetails.image.hashBase64}`;

                    return (
                      <div
                        key={selectedMaterial.id}
                        className="selected-material-item"
                      >
                        <img src={base64String} alt={materialDetails.title} />
                        <p>{materialDetails.title}</p>
                      </div>
                    );
                  }
                )}
              </div>
            )}
          </div>
        </div>{" "}
      </>
    </div>
  );
};

export default Material;
