import React, { useState, useEffect } from "react";
import axios from "axios";
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

const Dropdown = ({ onChange, selectedRoomTypes }) => {
  const [room, setRoom] = useState("");
  const [roomTypes, setRoomTypes] = useState([]);

  useEffect(() => {
    axios
      .get("https://api.emotix.dev/api/v1/e-prorab/room-types")
      .then((response) => {
        setRoomTypes(response.data);
      })
      .catch((error) => {
        console.error("Error fetching room types:", error);
      });
  }, []);

  const getAvailableRooms = () => {
    return selectedRoomTypes?.length ? selectedRoomTypes : roomTypes || [];
  };

  const getRoomDisplayName = (room) => {
    if (typeof room?.name === "object" && room?.name?.text) {
      return room.name.text;
    }
    return typeof room?.name === "string"
      ? room.name
      : room?.id || "Unknown Room";
  };

  useEffect(() => {
    const availableRooms = getAvailableRooms();
    if (!room && availableRooms.length > 0) {
      const defaultRoom = availableRooms[0];
      setRoom(defaultRoom.id);
      onChange?.(defaultRoom);
      sessionStorage.setItem("selectedRoom", JSON.stringify(defaultRoom));
    }
  }, [roomTypes, selectedRoomTypes, room, onChange]);

  const handleChange = (event) => {
    const selectedRoom = getAvailableRooms().find(
      (room) => room.id === event.target.value
    );
    if (selectedRoom) {
      setRoom(selectedRoom.id);
      onChange?.(selectedRoom);
      sessionStorage.setItem("selectedRoom", JSON.stringify(selectedRoom));
    }
  };

  const availableRooms = getAvailableRooms() || [];

  if (!availableRooms.length) {
    return null;
  }

  return (
    <Box>
      <FormControl fullWidth>
        <Select value={room || ""} onChange={handleChange} className="w-100">
          {availableRooms.map((room) => (
            <MenuItem key={room.id || Math.random()} value={room.id}>
              {getRoomDisplayName(room)}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
};

export default Dropdown;
