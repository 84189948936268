import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Header from "../../components/Header";
import axios from "axios";
import { SquareLoader } from "react-spinners";
import RoomDropdown from "../../components/RoomDropdown/RoomDropdown";
import { useTranslation } from "react-i18next";

const AllMaterials = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams();
  document.title = t("allMaterialsPrice.0");
  const headerTitle = t("allMaterialsPrice.1");
  const [shareId, setShareId] = useState("");
  const [homeArea, setHomeArea] = useState("");
  const [repair, setRepair] = useState([]);
  const [filteredRooms, setFilteredRooms] = useState([]);
  const [selectedRoom, setSelectedRoom] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [totalPrice, setTotalPrice] = useState("");
  const backwardNavigate = `/allmaterials/${id}`;
  const [shareWithFriend, setShareWithFriend] = useState("");
  const token = localStorage.getItem("token");
  useEffect(() => {
    setIsLoading(true);
    axios
      .get(`https://api.emotix.dev/api/v1/e-prorab/repairs/share/${id}`)
      .then((response) => {
        const result = response.data.split("/").slice(6).join("/");
        setShareId(result);
        setShareWithFriend(response.data);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setIsLoading(false);
      });
  }, [id]);

  useEffect(() => {
    if (shareId) {
      setIsLoading(true);
      if (!token) {
        navigate("/signup");
        return;
      }
      axios
        .get(
          `https://api.emotix.dev/api/v1/e-prorab/repairs/shared-repair/${shareId}`
        )
        .then((response) => {
          const repairData = response.data.property?.rooms || [];
          const validRooms = repairData.filter((room) =>
            room.roomComponents.some(
              (component) =>
                component.material.dependentMaterials &&
                component.material.dependentMaterials.length > 0
            )
          );
          setRepair(validRooms);
          setFilteredRooms(validRooms);
          const homeData = response.data.property || {};
          setHomeArea(homeData);
          setIsLoading(false);
        })
        .catch((error) => {
          console.error(error);
          setIsLoading(false);
        });
    }
  }, [shareId, token]);

  useEffect(() => {
    if (repair.length > 0) {
      const firstRoom = repair[0].roomType;
      setSelectedRoom(firstRoom);
      handleRoomChange(firstRoom);
    }
  }, [repair]);

  const handleRoomChange = (selectedRoom) => {
    setSelectedRoom(selectedRoom);

    if (selectedRoom) {
      const filtered = repair.filter(
        (room) => room.roomType.name.text === selectedRoom.name.text
      );
      setFilteredRooms(filtered);

      const total = filtered.reduce((sum, room) => {
        return (
          sum +
          room.roomComponents.reduce(
            (componentSum, component) => componentSum + (component.price || 0),
            0
          )
        );
      }, 0);
      setTotalPrice(total);
    } else {
      setFilteredRooms(repair);

      const total = repair.reduce((sum, room) => {
        return (
          sum +
          room.roomComponents.reduce(
            (componentSum, component) => componentSum + (component.price || 0),
            0
          )
        );
      }, 0);
      setTotalPrice(total);
    }
  };

  return (
    <div className="allmaterialsprice-section mx-3">
      {isLoading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
            backgroundColor: "#f5f5f5",
          }}
        >
          <SquareLoader
            color="#8c920b"
            cssOverride={{
              position: "absolute",
              top: "45%",
              bottom: "45%",
            }}
            size={100}
            speedMultiplier={1}
          />
        </div>
      ) : (
        <>
          <Header
            title={headerTitle}
            showProfile={true}
            backwardNavigate={backwardNavigate}
          />
          <div className="top-section d-flex align-items-center justify-content-center">
            <h5 className="my-auto"> {t("allMaterialsPrice.0")}</h5>
          </div>
          <div className="middle-section">
            <RoomDropdown
              onChange={handleRoomChange}
              selectedRoomTypes={repair.map((room) => room.roomType)}
            />
          </div>
          <div className="bottom-section">
            <table>
              <tbody>
                {filteredRooms.map((room, index) =>
                  room.roomComponents.map((component, compIndex) => (
                    <tr key={`${index}-${compIndex}`}>
                      <td>
                        {component.material.dependentMaterials.map(
                          (type, typeIndex) => (
                            <span key={typeIndex}>{type.name.text}</span>
                          )
                        )}
                      </td>
                      <td>
                        {component.consumption}{" "}
                        {component.material.dependentMaterials.map(
                          (type, typeIndex) => (
                            <span key={typeIndex}>{type.unitOfMeasure}</span>
                          )
                        )}
                      </td>
                      <td className="price-color">
                        {Math.round(component.price)}
                        {component.material.dependentMaterials.map(
                          (type, typeIndex) => (
                            <span key={typeIndex}>{type.currency}</span>
                          )
                        )}
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
            <table className="mt-2">
              <tbody>
                <tr>
                  <td className="bg-body-secondary">
                    {t("allMaterialsPrice.2")}
                  </td>
                  <td className="bg-body-secondary">{homeArea.area} kv.m.</td>
                  <td className="bg-body-secondary price-color">
                    {Math.round(totalPrice)} AZN
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div className="button-section">
            <Link to={`/shops/${id}`}>
              <button className="btn py-3 global-button">
                {t("allMaterialsPrice.3")}
              </button>
            </Link>
            <button
              className="btn py-3 share-with-friend mt-2"
              data-bs-toggle="modal"
              data-bs-target="#shareFriendModal"
            >
              {t("allMaterialsPrice.4")}
            </button>
          </div>
          <div
            className="modal fade"
            id="shareFriendModal"
            tabIndex="-1"
            aria-labelledby="shareFriendModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header d-flex align-items-center justify-content-between">
                  <h5 className="modal-title">{t("allMaterialsPrice.5")}</h5>
                  <i
                    className="fa-regular fa-circle-xmark fs-3"
                    type="button"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></i>
                </div>
                <hr className="mb-0 mt-3 mx-auto" />
                <div className="modal-body">
                  <h6>{t("allMaterialsPrice.6")}</h6>
                  <div className="social-media-section d-flex align-items-center justify-content-between">
                    {/* WhatsApp */}
                    <div className="whatsapp-section">
                      <a
                        href={`https://api.whatsapp.com/send?text=${encodeURIComponent(
                          shareWithFriend
                        )}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <i className="fa-brands fa-whatsapp"></i>
                      </a>
                    </div>

                    {/* Telegram */}
                    <div className="telegram-section">
                      <a
                        href={`https://t.me/share/url?url=${encodeURIComponent(
                          shareWithFriend
                        )}&text=${encodeURIComponent("Check this out!")}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <i className="fa-brands fa-telegram"></i>
                      </a>
                    </div>

                    {/* Facebook */}
                    <div className="facebook-section">
                      <a
                        href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
                          shareWithFriend
                        )}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <i className="fa-brands fa-facebook"></i>
                      </a>
                    </div>

                    {/* Instagram */}
                    <div className="instagram-section">
                      <a
                        href="https://www.instagram.com/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <i className="fa-brands fa-instagram"></i>
                      </a>
                    </div>

                    {/* Discord */}
                    <div className="discord-section">
                      <a
                        href={`https://discord.com/channels/@me`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <i className="fa-brands fa-discord"></i>
                      </a>
                    </div>
                  </div>

                  <h6>{t("allMaterialsPrice.7")}</h6>
                </div>

                <div className="modal-footer">
                  <div className="share-link-section d-flex align-items-center">
                    <div className="left-section ms-3">
                      <h5>
                        {shareWithFriend.length > 20
                          ? `${shareWithFriend.slice(0, 20)}...`
                          : shareWithFriend}
                      </h5>
                    </div>
                    <div className="right-section ms-auto me-1">
                      <button
                        className="btn"
                        onClick={() => {
                          navigator.clipboard.writeText(shareWithFriend);
                        }}
                      >
                        <i className="fa-solid fa-copy"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default AllMaterials;
