import React, { useState, useEffect } from "react";
import HeaderAccount from "../../../components/HeaderAccount";
import { useTranslation } from "react-i18next";
import { SquareLoader } from "react-spinners";
import axios from "axios";

const RepairHistory = () => {
  const { t } = useTranslation();
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  document.title = t("repairHistory.0");
  const headerTitle = t("repairHistory.0");
  const backwardNavigate = "/account";

  useEffect(() => {
    const fetchOrders = async () => {
      try {
        const token = localStorage.getItem("token");
        const storedUser = localStorage.getItem("user");

        if (!storedUser) {
          console.warn("User not found in localStorage");
          return;
        }

        const parsedUser = JSON.parse(storedUser);
        const userId = parsedUser.id;

        const response = await axios.get(
          "https://api.emotix.dev/api/v1/e-prorab/orders",
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        const filteredOrders = response.data.filter(
          (order) => order.user.id === userId
        );

        if (filteredOrders.length > 0) {
          const repairs = filteredOrders.map((order) => order.repair);
          setOrders(repairs);
        }
      } catch (error) {
        console.error("Error fetching orders:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchOrders();
  }, []);

  if (loading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
          backgroundColor: "#f5f5f5",
        }}
      >
        <SquareLoader
          color="#8c920b"
          cssOverride={{
            position: "absolute",
            top: "45%",
            bottom: "45%",
          }}
          size={100}
          speedMultiplier={1}
        />
      </div>
    );
  }

  if (!orders || orders.length === 0) {
    return <div>No repair history found</div>;
  }

  return (
    <div className="repair-history-section mx-3">
      <HeaderAccount title={headerTitle} backwardNavigate={backwardNavigate} />
      {orders.map((type, index) => (
        <React.Fragment key={index}>
          <div className="top-section d-flex align-items-center gap-2">
            <span>{index + 1}.</span>
            <>
              <i className={`${type.property.propertyType.iconType}`}></i>
              <h4>{type.property.propertyType.name.text}</h4>
            </>
          </div>
          <div className="bottom-section">
            <div className="row g-3">
              {type.property.rooms.map((room) => (
                <div className="col-12 room-section" key={room.id}>
                  <div className="d-flex align-items-center">
                    <div className="left">
                      <i className={`${room.roomType.iconType} mx-3`}></i>
                    </div>
                    <div className="right">
                      <h6>{room.roomType.name.text}</h6>
                      <p>2025 - 2025</p>
                    </div>
                  </div>
                  <hr className="horizontal-line" />
                </div>
              ))}
            </div>
          </div>
        </React.Fragment>
      ))}
    </div>
  );
};

export default RepairHistory;
